import React from 'react';
import { useIntl } from 'umi';
import { Space } from 'antd';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';
import AnalysisChart from './charts/AnalysisChart';

import upArrow from 'assets/upArrow.png';
import downArrow from 'assets/downArrow.png';

const formatNumber = (num) => {
  if (num == null || num === undefined) {
    return '-';
  }
  const rounded = Math.round(num * 10) / 10; // 四舍五入到一位小数
  if (Number.isInteger(rounded)) {
    return rounded; // 整数直接返回
  }
  return Number(rounded.toFixed(1)); // 转换为数字，去掉末尾的 .0
};

const MentalStatusNext = ({ hfsReportOverAllFeedback, userInfo, pageNum }) => {
  const intl = useIntl();

  const { mentalFeedbacks = [] } = hfsReportOverAllFeedback || {};
  const { avatarContent } = userInfo || {};

  const showData = mentalFeedbacks.slice(3, 5);

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({
      id: 'TransitionsMental',
    }),
    headerLogo: '',
  };

  const getDifference = (early, later) => {
    if (
      early === null ||
      early === undefined ||
      later === undefined ||
      later === null
    ) {
      return '-';
    }

    const _early = early.toFixed(1);
    const _later = later.toFixed(1);

    const difference = parseInt((Math.abs(_early - _later) / _early) * 100);

    if (_early === _later || _early == 0) {
      return '-';
    } else {
      return (
        <Space size={2}>
          <div style={{ width: '32px' }}>{difference + '%'}</div>
          <img
            src={_early > _later ? downArrow : upArrow}
            style={{ width: '10px' }}
          />
        </Space>
      );
    }
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.categoryAnalysis}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom tagTitle={intl.formatMessage({ id: 'Mental Statuses' })} />
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'Mental StatusesDes' },
              { name: avatarContent },
            )}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'six chartsDes' })}
          </div>

          {showData.map((item) => {
            const {
              feedbackProperty = {},
              linearValues = [],
              values = [],
            } = item;
            const { name, id } = feedbackProperty || {};
            const chartProps = {
              analysisValues: values,
              linearValues,
              showKey: name,
              feedbackProperty,
            };
            return (
              <div style={{ marginTop: '30px' }} key={id}>
                <AnalysisChart {...chartProps} />
              </div>
            );
          })}

          <table style={{ marginTop: '30px', whiteSpace: 'pre-line' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }} rowSpan="2">
                  {intl.formatMessage({ id: 'Mental Statuses' })}
                </th>
                <th style={{ textAlign: 'center' }} colSpan="3">
                  {intl.formatMessage({ id: 'Mean' })}
                </th>
                <th style={{ textAlign: 'center' }} colSpan="3">
                  {intl.formatMessage({ id: 'SD' })}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Early' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Later' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Difference' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Early' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Later' })}
                </th>
                <th style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'Difference' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {mentalFeedbacks &&
                mentalFeedbacks.length > 0 &&
                mentalFeedbacks.map((item) => {
                  const { feedbackProperty, mentalStatus } = item || {};
                  const { displayName, id } = feedbackProperty || {};
                  const { earlyAvg, earlySd, laterAvg, laterSd } =
                    mentalStatus || {};

                  return (
                    <tr key={id}>
                      <th style={{ textAlign: 'center' }}>{displayName}</th>
                      <td style={{ textAlign: 'center' }}>
                        {formatNumber(earlyAvg)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {laterAvg ? formatNumber(laterAvg) : '-'}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {getDifference(earlyAvg, laterAvg)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {formatNumber(earlySd)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {laterSd ? formatNumber(laterSd) : '-'}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {getDifference(earlySd, laterSd)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MentalStatusNext);
