import React, { useEffect } from 'react';
import { connect, useIntl, history } from 'umi'
import { Card, Spin, Tabs, Button, Table, Divider, Pagination, Menu, Dropdown, Drawer, message, Tooltip } from 'antd'
import TrainingStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/TrainingStatistics';
import DocumentTitle from 'react-document-title';
import CourseStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/CourseStatistics';
import MusicRecord from 'components/MusicRecord';
import BrainLineChart from 'cognitiveleap-core-us/components/Charts/ILSChart/BrainLineChart'
import AdminClockInRecord from 'components/ClockInRecord';
import BrainRecord from 'cognitiveleap-core-us/components/Charts/GroupChart/BrainRecord';
import Loading from 'components/Loading'
import SearchComponent from './search'
import style from './index.less';
import moment from 'moment'
import { getToken, getTenantId, getUserTenantId } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import subjectDefault from 'assets/subject_default.png'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { isLegitimateDate, getFormatDate, getAssessmentStatus } from 'utils/utils'

const { TabPane } = Tabs;
const { mobileUrl } = config
const dateOption = ['createTime', 'realStartTime', 'endTime', 'courseTime']
const noNeedSearchOption = ['userNickName', 'planName']

const TrainList = ({ trainList, loading, dispatch, currentUser }) => {

    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'trainList/initPage' })
    }, [])

    const {
        searchOption,
        isOpenUp,
        pagination,
        planList,
        recordPagination,
        ilsDrawerVisible,
        ilsActiveKey,
        shouldGetData,
        ilsparentRecord,
        ilstrainerRecord,
        ilsappRecord,
        brainRecord,
        currentTablePlan,
        musicRecord,
        currentTenant
    } = trainList;

    const { auth, adminRoles, current, changedTenant } = currentUser
    const { institutionType, medicalPublicWithName = false } = changedTenant || {};
    const isAdmin = adminRoles.length > 0 && current === null
    const { roles = [] } = current || {}
    const { grantedPolicies } = auth
    const hasOfflinePlanReadPermission = grantedPolicies['RocketSystem.UserOfflinePlan'];
    const isEnterpriseAgent = roles.some(item => item.name === 'EnterpriseAgent')

    const getPlanListLoading = loading.effects['trainList/getPlanList']
    const isRecordLoading = loading.effects['trainList/getRecord'];
    const brainIsLoading = loading.effects['trainList/getBrainRecord']
    const musicRecordIsLoading = loading.effects['trainList/getMusicRecord']

    const switchStatus = status => {
        switch (status) {
            case 'None':
                return intl.formatMessage({ id: 'notRealStart' })
            case 'Created':
                return intl.formatMessage({ id: 'created' })
            case 'Started':
                return intl.formatMessage({ id: 'ongoing' })
            case 'Ended':
                return intl.formatMessage({ id: 'finished' })
            case 'Feedbacked':
                return intl.formatMessage({ id: 'doneFeek' })
            default:
                return ''
        }
    }

    const onClickMenuItem = ({ key }) => {
        const reportUrl = `${mobileUrl}/m/rocketReport?to=${key}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`
        window.open(reportUrl, '_blank')
    }

    const renderTextDetail = (record) => {
        const { assessmentStatus } = record
        const menu = <Menu onClick={onClickMenuItem} className={style.assessmentContent}>
            {assessmentStatus.map(item => {
                const { userAssessmentId, isFinished, assessment, userAssessment, assessmentType } = item
                const { title } = assessment
                const { status, needPay } = userAssessment || {}
                const tempStatus = status === 'ReportedReady' && !needPay ? 'hasGetReport' : status
                return (
                    <Menu.Item key={userAssessmentId} disabled={!isFinished}>
                        <span>
                            <a>
                                {title}
                            </a>
                            <span style={{ margin: '0 10px' }}>
                                {
                                    getAssessmentStatus(tempStatus, intl)
                                }
                            </span>
                            {
                                isFinished && userAssessment && userAssessment.finishTime
                                && moment(userAssessment.finishTime)
                                    .local()
                                    .format('YYYY/MM/DD HH:mm:ss')
                            }
                            <span style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.5)' }}>
                                {
                                    {
                                        'PreAssessment': `${intl.formatMessage({ id: 'pre_assessment' })}`,
                                        'MediumAssessment': `${intl.formatMessage({ id: 'mid_assessment' })}`
                                    }[assessmentType]
                                }
                            </span>
                        </span>
                    </Menu.Item>
                )
            })}
        </Menu>

        return (
            <span key="assessmentList">
                <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <span>{intl.formatMessage({ id: 'assessmentDetails' })}</span>
                    </a>
                </Dropdown>
            </span>
        )
    }

    const showChartRecord = (record) => {
        dispatch({ type: 'trainList/showChartRecord', payload: record })
    }

    const jumpToRecordDetail = (record) => {
        dispatch({
            type: 'trainList/jumpToRecordDetail',
            payload: { record, isAdmin }
        })
    }

    const jumpToInterventionReport = (record) => {
        const { userId, id, tenantId } = record || {};
        const url = '/interventionReport?id=' + id + '&userId=' + userId + '&suspension=true';
        window.open(isAdmin ? url + '&currentTenantId=' + tenantId : url, '_blank')
    }

    let columns = [];

    if (institutionType === 'Medical_Public') {
        columns = [
            {
                title: intl.formatMessage({ id: 'creatTime' }),
                dataIndex: 'creationTime',
                key: 'creationTime',
                render: (text, record, index) => {
                    const { status } = record
                    const time = status && text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'trainingPlanName' }),
                dataIndex: 'displayName',
                key: 'displayName',
                render: (text, record, index) => <span key={index}>{text}</span>
            },
            {
                title: intl.formatMessage({ id: 'status' }),
                dataIndex: 'status',
                key: 'status',
                render: (text, record, index) => <span key={index}>{switchStatus(text)}</span>
            },
            medicalPublicWithName ? {
                title: intl.formatMessage({ id: 'clinicNumber' }),
                dataIndex: 'outpatientNumber',
                key: 'outpatientNumber',
                render: (text, record, index) => <span key={index}>{text}</span>
            } : {},
            isEnterpriseAgent ? {} : {
                title: intl.formatMessage({ id: medicalPublicWithName ? 'subject' : 'clinicNumber' }),
                dataIndex: 'user',
                key: 'user',
                render: (item, record, index) => {
                    const { picture, userName, status, userId } = record
                    const showUserName = userName && userName.length > 5 ? userName.slice(0, 5) + '...' : userName
                    return (
                        status &&
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginLeft: '5px' }}>
                                <Tooltip placement="topRight" title={userName}>
                                    {
                                        hasOfflinePlanReadPermission ?
                                            <a onClick={() => {
                                                history.push({
                                                    pathname: isAdmin ? '/system/manage/subjectDetail' : '/system/center/detail/subjectDetail',
                                                    query: {
                                                        id: userId
                                                    }
                                                })
                                            }}>
                                                {showUserName}
                                            </a>
                                            :
                                            <span>{showUserName}</span>
                                    }

                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: intl.formatMessage({ id: 'Elapsed class hours' }),
                dataIndex: 'doCourseNum',
                key: 'doCourseNum',
                render: (text, record, index) => <span key={index}>{text}</span>,
            },
            {
                title: intl.formatMessage({ id: 'onlineSessions' }),
                dataIndex: 'offlineCount',
                key: 'offlineCount',
                render: (text, record, index) => <span key={index}>{text}</span>
            },
            {
                title: intl.formatMessage({ id: 'totalSessions' }),
                dataIndex: 'totalNum',
                key: 'totalNum',
                render: (text, record, index) => {
                    const { status } = record
                    return <span key={index}>{status && text}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'realStartTime' }),
                dataIndex: 'start',
                key: 'start',
                render: (text, record, index) => {
                    const time = text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'recentFinishingTime' }),
                dataIndex: 'lastClassTime',
                key: 'lastClassTime',
                render: (text, record, index) => {
                    const time = text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            isEnterpriseAgent ? {} :  {
                title: intl.formatMessage({ id: 'action' }),
                key: 'action',
                fixed: 'right',
                render: (text, record, index) => {
                    const { status } = record
                    return status && status !== 'None' && status !== 'Created' ? (
                        <span key={index}>
                            {
                                hasOfflinePlanReadPermission &&
                                <React.Fragment>
                                    {isAdmin && (
                                        <>
                                            <a onClick={() => jumpToInterventionReport(record)}>{intl.formatMessage({ id: 'Intervention Report' })}</a>
                                            <Divider type="vertical" />
                                        </>
                                    )}
                                    <a onClick={() => { showChartRecord(record) }}>{intl.formatMessage({ id: 'charts_feedback' })}</a>
                                    <Divider type="vertical" />
                                    {
                                        renderTextDetail(record)
                                    }
                                    <Divider type="vertical" />
                                </React.Fragment>
                            }
                            <a onClick={() => { jumpToRecordDetail(record) }}>{intl.formatMessage({ id: 'planDetails' })}</a>
                        </span>
                    )
                        :
                        null
                },
            }
        ]
    } else {
        columns = [
            {
                title: intl.formatMessage({ id: 'creatTime' }),
                dataIndex: 'creationTime',
                key: 'creationTime',
                render: (text, record, index) => {
                    const { status } = record
                    const time = status && text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'trainingPlanName' }),
                dataIndex: 'displayName',
                key: 'displayName',
                render: (text, record, index) => <span key={index}>{text}</span>
            },
            {
                title: intl.formatMessage({ id: 'status' }),
                dataIndex: 'status',
                key: 'status',
                render: (text, record, index) => <span key={index}>{switchStatus(text)}</span>
            },
            isEnterpriseAgent ? {} : {
                title: intl.formatMessage({ id: 'user' }),
                dataIndex: 'user',
                key: 'user',
                render: (item, record, index) => {
                    const { picture, userName, status, userId } = record
                    const showUserName = userName && userName.length > 5 ? userName.slice(0, 5) + '...' : userName
                    return (
                        status &&
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <div><img src={picture || subjectDefault} style={{ width: 50, height: 50, borderRadius: '10px', objectFit: 'cover' }} /></div> */}
                            <div style={{ marginLeft: '5px' }}>
                                {/* <Tooltip placement="topRight" title={userName}> */}
                                    {
                                        hasOfflinePlanReadPermission ?
                                            <a onClick={() => {
                                                history.push({
                                                    pathname: isAdmin ? '/system/manage/subjectDetail' : '/system/center/detail/subjectDetail',
                                                    query: {
                                                        id: userId
                                                    }
                                                })
                                            }}>
                                                {userName}
                                            </a>
                                            :
                                            <span>{userName}</span>
                                    }

                                {/* </Tooltip> */}
                            </div>
                        </div>
                    )
                }
            },
            {
                title: intl.formatMessage({ id: 'Elapsed class hours' }),
                dataIndex: 'doCourseNum',
                key: 'doCourseNum',
                render: (text, record, index) => <span key={index}>{text}</span>,
            },
            {
                title: intl.formatMessage({ id: 'onlineSessions' }),
                dataIndex: 'offlineCount',
                key: 'offlineCount',
                render: (text, record, index) => <span key={index}>{text}</span>
            },
            // {
            //     title: intl.formatMessage({ id: 'offlineSessions' }),
            //     dataIndex: 'onlineCount',
            //     key: 'onlineCount',
            //     render: (text, record, index) => <span key={index}>{text}</span>
            // },
            {
                title: intl.formatMessage({ id: 'totalSessions' }),
                dataIndex: 'totalNum',
                key: 'totalNum',
                render: (text, record, index) => {
                    const { status } = record
                    return <span key={index}>{status && text}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'realStartTime' }),
                dataIndex: 'start',
                key: 'start',
                render: (text, record, index) => {
                    const time = text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            {
                title: intl.formatMessage({ id: 'recentFinishingTime' }),
                dataIndex: 'lastClassTime',
                key: 'lastClassTime',
                render: (text, record, index) => {
                    const time = text && moment(text).local().format('YYYY/MM/DD HH:mm')
                    return <span key={index}>{time}</span>
                }
            },
            isEnterpriseAgent ? {} : {
                title: intl.formatMessage({ id: 'action' }),
                key: 'action',
                fixed: 'right',
                render: (text, record, index) => {
                    const { status } = record
                    return status && status !== 'None' && status !== 'Created' ? (
                        <span key={index}>
                            {
                                hasOfflinePlanReadPermission &&
                                <React.Fragment>
                                    {isAdmin && (
                                        <>
                                            <a onClick={() => jumpToInterventionReport(record)}>{intl.formatMessage({ id: 'Intervention Report' })}</a>
                                            <Divider type="vertical" />
                                        </>
                                    )}
                                    <a onClick={() => { showChartRecord(record) }}>{intl.formatMessage({ id: 'charts_feedback' })}</a>
                                    <Divider type="vertical" />
                                    {
                                        renderTextDetail(record)
                                    }
                                    <Divider type="vertical" />
                                </React.Fragment>
                            }
                            <a onClick={() => { jumpToRecordDetail(record) }}>{intl.formatMessage({ id: 'planDetails' })}</a>
                        </span>
                    )
                        :
                        null
                },
            }
        ]
    }

    const onSearch = () => {
        dispatch({ type: 'trainList/getPlanList' })
    }

    const onChangeSearchOption = async (type, value) => {
        const cloneSearchOption = JSON.parse(JSON.stringify(searchOption))
        let verifyFlag = true;
        if (dateOption.includes(type)) {
            cloneSearchOption[type].startTime = value && value[0] ? getFormatDate(value[0], true) : ''
            cloneSearchOption[type].endTime = value && value[1] ? getFormatDate(value[1], false) : ''
        } else {
            cloneSearchOption[type] = value
        }

        if (type == 'realStartTime' || type == 'endTime') {
            verifyFlag = isLegitimateDate(cloneSearchOption['realStartTime'], cloneSearchOption['endTime']);
        }

        if (verifyFlag) {
            await dispatch({
                type: 'trainList/updateState',
                payload: {
                    searchOption: cloneSearchOption
                }
            })
            if (!noNeedSearchOption.includes(type)) {
                onSearch();
            }
        } else {
            message.error('结束时间应大于等于正式开启时间！');
        }
    }

    const searchProps = {
        searchOption,
        isOpenUp,
        onSearch,
        onChange: onChangeSearchOption,
        onClear: () => {
            dispatch({ type: 'trainList/onClear' })
        },
        changeState: (type, value) => {
            dispatch({
                type: 'trainList/updateState',
                payload: {
                    [type]: value
                }
            })
        }
    }

    const onChangeTablePage = async (page) => {
        const cloneSearchPagination = JSON.parse(JSON.stringify(pagination))
        cloneSearchPagination.current = page;
        await dispatch({ type: 'trainList/updateState', payload: { pagination: cloneSearchPagination } })
        onSearch();
    }

    const onChangeTablePageSize = async (current, pageSize) => {
        const cloneSearchPagination = JSON.parse(JSON.stringify(pagination))
        cloneSearchPagination.pageSize = pageSize;
        await dispatch({ type: 'trainList/updateState', payload: { pagination: cloneSearchPagination } })
        onSearch();
    }

    const getRecord = (key) => {
        const currentKey = key || ilsActiveKey;
        if (key != 'chart') {
            const pagination = recordPagination[currentKey]
            dispatch({
                type: 'trainList/getRecord',
                payload: {
                    id: currentKey == 'ilsapp' ? currentTablePlan && currentTablePlan.onlinePlanId : currentTablePlan && currentTablePlan.id,
                    type: currentKey,
                    current: pagination.current + 1,
                    ilsType: key
                }
            })
        } else {
            dispatch({
                type: 'trainList/updateState',
                payload: {
                    shouldGetData: true
                }
            })
        }
    }

    const callback = () => {
        dispatch({
            type: 'trainList/updateState',
            payload: {
                shouldGetData: false
            }
        })
    }

    const userOfflineTrainingPlanProps = {
        UserOfflineTrainingPlanId: currentTablePlan && currentTablePlan.id,
        shouldGetData,
        Loading,
        callback
    }

    const ilsparentPropsData = {
        dataSource: ilsparentRecord,
        loadData: getRecord,
        isLoading: isRecordLoading
    }

    const ilstrainerPropsData = {
        dataSource: ilstrainerRecord,
        loadData: getRecord,
        isLoading: isRecordLoading
    }

    const ilsappPropsData = {
        dataSource: ilsappRecord,
        loadData: getRecord,
        isLoading: isRecordLoading
    }

    const dataSource = planList.map(item => {
        const preStatus = item.assessmentStatus && item.assessmentStatus.length > 0 ?
            item.assessmentStatus.filter(item => item.assessmentType == 'PreAssessment').every(item => item.isFinished) ? '已完成' : '未完成'
            :
            '未完成'

        return {
            ...item,
            key: item.id,
            preStatus,
            offlineCount: item.doCourseNum - item.onlineCount,
            doCourseNum: item.doCourseNum
        }
    })

    const totalShowText = intl.formatMessage({ id: 'total' })
    const itemShowText = intl.formatMessage({ id: 'items' })

    return (
        <DocumentTitle title={intl.formatMessage({ id: 'trainingPlanList' })}>
            <Card
                title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'trainingPlanList' })}</b>}
                bordered={false}
                extra={isAdmin &&
                    <AnalysisTenantSelect
                        showAll={true}
                        currentTenant={currentTenant}
                        onChange={(e) => {
                            dispatch({ type: 'trainList/initPage', payload: { id: e } })
                        }}
                    />}
            >
                <div className={style.iLsTable}>
                    <Spin
                        spinning={getPlanListLoading}
                    >
                        <SearchComponent {...searchProps} />
                        <Button style={{ marginBottom: '20px' }} onClick={() => {
                            dispatch({ type: 'trainList/downloadExcel', payload: { intl } })
                        }}>
                            {intl.formatMessage({ id: 'exportList' })}
                        </Button>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            className={style.iLsTableContent}
                            scroll={{ x: true }}
                        />
                        <Pagination
                            style={{ marginTop: '20px' }}
                            showQuickJumper
                            pageSizeOptions={['10', '15', '20']}
                            current={pagination.current}
                            total={pagination.total}
                            pageSize={pagination.pageSize}
                            onChange={page => onChangeTablePage(page)}
                            onShowSizeChange={(current, pageSize) => onChangeTablePageSize(current, pageSize)}
                            showTotal={total => `${totalShowText} ${total} ${itemShowText}`}
                        />
                    </Spin>
                </div>
                <Drawer
                    open={ilsDrawerVisible}
                    width={700}
                    onClose={() => {
                        dispatch({
                            type: 'trainList/updateState',
                            payload: {
                                ilsDrawerVisible: false
                            }
                        })
                    }}
                    title={<b>{`${currentTablePlan && currentTablePlan.displayName} ` + intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}</b>}
                >
                    <Tabs type="card"
                        activeKey={ilsActiveKey}
                        onChange={(key) => {
                            dispatch({ type: 'trainList/updateState', payload: { ilsActiveKey: key } });
                            switch (key) {
                                case 'brain':
                                    dispatch({ type: 'trainList/getBrainRecord', payload: { id: currentTablePlan && currentTablePlan.id } });
                                    break;
                                case 'music':
                                    dispatch({ type: 'trainList/getMusicRecord', payload: currentTablePlan })
                                    break
                                default:
                                    getRecord(key);
                            }
                        }}
                    >
                        <TabPane tab={intl.formatMessage({ id: 'chart' })} key="chart">
                            <div style={{ width: '80%', margin: 'auto' }}>
                                <TrainingStatistics {...userOfflineTrainingPlanProps} />
                                {
                                    currentTablePlan && currentTablePlan.audioType === 'BrainAudio' && hasOfflinePlanReadPermission &&
                                    <BrainLineChart {...userOfflineTrainingPlanProps} />
                                }
                                <CourseStatistics {...userOfflineTrainingPlanProps} />
                            </div>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({ id: 'trainerFeedback' })} key="ilstrainer">
                            <AdminClockInRecord {...ilstrainerPropsData} />
                        </TabPane>
                        <TabPane tab={intl.formatMessage({ id: 'overallFeedback' })} key="ilsparent">
                            <AdminClockInRecord {...ilsparentPropsData} />
                        </TabPane>
                        <TabPane tab={intl.formatMessage({ id: 'parentFeedback' })} key="ilsapp">
                            <AdminClockInRecord {...ilsappPropsData} />
                        </TabPane>
                        {
                            hasOfflinePlanReadPermission && currentTablePlan && currentTablePlan.audioType === 'BrainAudio' &&
                            <TabPane tab={intl.formatMessage({ id: 'HFSMusicRecords' })} key="brain">
                                <BrainRecord brainRecord={brainRecord} isLoading={brainIsLoading} />
                            </TabPane>
                        }
                        {
                            currentTablePlan && currentTablePlan.audioType == 'SenseAudio' &&
                            <TabPane tab={intl.formatMessage({ id: 'musicFeedback' })} key="music">
                                <MusicRecord musicRecord={musicRecord} isLoading={musicRecordIsLoading} />
                            </TabPane>
                        }
                    </Tabs>
                </Drawer>
            </Card>
        </DocumentTitle>
    )
}

export default connect(({ trainList, loading, user }) => ({ trainList, loading, currentUser: user.currentUser }))(TrainList);