import React from 'react';
import cogleapLogo from 'assets/cogleap3.png';
import { useIntl } from 'umi';

import moment from 'moment';

import styles from './index.less';

const CoverPage = ({ userInfo }) => {
  const intl = useIntl();

  const { avatarContent } = userInfo || {};

  return (
    <div className={styles.page_div}>
      <div className={styles.coverPage}>
        <div className={styles.reportTitle}>
          {intl.formatMessage({ id: 'HFS Intervention Report' })}
        </div>

        <div className={styles.centerContent}>
          <div>
            {intl.formatMessage({ id: 'Child Name' })}: {avatarContent}
          </div>
          <div>
            {intl.formatMessage({ id: 'Report Date' })}:{' '}
            {moment().format('MM/DD/YYYY')}
          </div>
        </div>

        <div className={styles.logo}>{<img src={cogleapLogo} />}</div>
        <div className={styles.powerBy}>
          {intl.formatMessage({ id: 'By COGLEAP' })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CoverPage);
