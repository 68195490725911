import React, { useState } from 'react';
import { Modal, Radio, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deepCopy } from 'cognitiveleap-core-us/utils/utils';
import { fixedZero } from 'utils/utils';
import { useIntl } from 'umi';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './index.less';

const StartClassModal = (props) => {
  const {
    type = 'add',
    allData = {},
    modalContentLoding = false,
    childClassList = [],
    openClassModal = false,
    closeModal = () => {},
    onOk = () => {},
    onRadioChange = () => {},
  } = props;

  const intl = useIntl();

  const [classModalLoading, setClassModalLoading] = useState(false);

  const remainClassList = childClassList.filter(
    (item) => item.remainClass <= 1,
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: type === 'add' ? 'attendance' : 'switchClassStatus',
      })}
      open={openClassModal}
      width={800}
      confirmLoading={classModalLoading}
      onOk={async () => {
        setClassModalLoading(true);
        await onOk();
        setClassModalLoading(false);
      }}
      onCancel={() => {
        closeModal();
      }}
      okText={intl.formatMessage({ id: 'sureConfirm' })}
      cancelText={intl.formatMessage({ id: 'cancel' })}
    >
      <Spin spinning={modalContentLoding}>
        <div className={styles.openClass}>
          {type === 'add' && remainClassList.length > 0 && (
            <div className={styles.remainClassCom}>
              <ExclamationCircleOutlined className={styles.icon} />
              {intl.formatMessage(
                { id: 'remainClassTips' },
                {
                  childList: remainClassList
                    .map((item) => item.userName)
                    .join('、'),
                  remainList: (
                    <span style={{ color: 'red' }}>
                      {remainClassList
                        .map((item) => item.remainClass)
                        .join('、')}
                    </span>
                  ),
                },
              )}
            </div>
          )}
          <div className={styles.title}>
            {remainClassList.length <= 0 && (
              <ExclamationCircleOutlined className={styles.icon} />
            )}
            <span className={styles.titleText}>
              {intl.formatMessage({
                id:
                  type === 'add'
                    ? 'confirmTheAttendanceOfStudents'
                    : 'selectTheAttendanceOfStudents',
              })}
              :
            </span>
          </div>
          {/* <div className={styles.className}>
            <div className={styles.nameText}>训练课时：</div>
            <div>
              {allData &&
                allData.trainingPlanV2 &&
                allData.trainingPlanV2.displayName}
            </div>
          </div> */}
          <div className={styles.classType}>
            {/* <div className={styles.typeText}>优脑音乐和上课方式：</div> */}
            <div className={styles.childList}>
              <InfiniteScroll
                dataLength={childClassList && childClassList.length}
                height={type === 'add' ? 200 : 100}
              >
                {childClassList &&
                  childClassList.length > 0 &&
                  childClassList.map((item) => {
                    // 待生成音乐 不可选择线上线下，可请假，不传递给后端儿童信息
                    // 请假 传递儿童信息，不传递platform则为请假
                    if (type === 'add') {
                      const { userId, userName, platform, isFree, userMusic } =
                        item;

                      return (
                        <div key={userId} className={styles.childInfo}>
                          <div className={styles.childName}>{userName}: </div>
                          <div className={styles.choices}>
                            <Radio.Group
                              onChange={(e) => {
                                const value = e.target.value;
                                // 切换修改线上线下
                                let tempData = deepCopy(childClassList);
                                tempData.forEach((temp, index) => {
                                  if (temp.userId === userId) {
                                    tempData[index].platform = value;
                                  }
                                });
                                onRadioChange(tempData);
                              }}
                              // disabled={!isFree}
                              value={platform}
                            >
                              <Radio
                                disabled={!userMusic || !isFree}
                                value={'Web'}
                              >
                                {intl.formatMessage({ id: 'offline' })}
                              </Radio>
                              <Radio
                                disabled={!userMusic || !isFree}
                                value={'App'}
                              >
                                {intl.formatMessage({ id: 'online' })}
                              </Radio>
                              <Radio value={'Leave'}>
                                {intl.formatMessage({ id: 'absent' })}
                              </Radio>
                            </Radio.Group>
                          </div>
                          {type === 'add' && (
                            <div className={styles.musicInfo}>
                              {isFree ? (
                                <div>
                                  {userMusic ? (
                                    <div>
                                      {userMusic.code.toUpperCase()}{' '}
                                      {fixedZero(userMusic.musicCourseNum)}
                                    </div>
                                  ) : (
                                    <div className={styles.musicGenra}>
                                      {intl.formatMessage({
                                        id: 'HFSMusicToBeGenerated',
                                      })}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {intl.formatMessage({ id: 'OccupiedClass' })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      const {
                        id,
                        userName,
                        music,
                        platform,
                        userMusics,
                        musicListenedPercent,
                      } = item;

                      return (
                        <div key={id} className={styles.childInfo}>
                          <div className={styles.childName}>{userName}: </div>
                          <div className={styles.choices}>
                            <Radio.Group
                              onChange={(e) => {
                                const value = e.target.value;
                                // 切换修改线上线下
                                let tempData = deepCopy(childClassList);
                                tempData[0].music = value;
                                onRadioChange(tempData);
                              }}
                              value={music}
                            >
                              <Radio
                                disabled={!userMusics}
                                value={intl.formatMessage({ id: 'online' })}
                              >
                                {intl.formatMessage({ id: 'online' })}
                              </Radio>
                              <Radio
                                // disabled={!userMusics}
                                disabled={
                                  parseInt(musicListenedPercent * 100) >= 50 &&
                                  platform === 'Web'
                                }
                                value={intl.formatMessage({ id: 'offline' })}
                              >
                                {intl.formatMessage({ id: 'offline' })}
                              </Radio>
                              {/* 收听进度大于等于50%不可以切换为请假 */}
                              <Radio
                                disabled={
                                  parseInt(musicListenedPercent * 100) >= 50
                                }
                                value={intl.formatMessage({
                                  id: 'absentOther',
                                })}
                              >
                                {intl.formatMessage({ id: 'absent' })}
                              </Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      );
                    }
                  })}
                {type === 'change' && (
                  <div className={styles.des}>
                    {intl.formatMessage({ id: 'lisenMoreThanFive' })}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default StartClassModal;
