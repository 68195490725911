import React, { useState, useEffect, useRef } from 'react';
import { Button, Spin, message } from 'antd';
import { setLocale, getLocale, useIntl } from 'umi';
import { useReactToPrint } from 'react-to-print';
import DocumentTitle from 'react-document-title';
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';
import { resolveSurveyData } from 'cognitiveleap-core-us/utils/utils';

import { CenterGetSubjectInfo } from 'services/userManager';
import { GetTrainerDetail } from 'services/trainingSystem';
import { SetLanguage } from 'services/language';
import { GetTrialPlan, GetCategoryPerformance } from 'services/trialPlan';
import { GetAssessmentDetail } from 'services/rocketService/Assessment';

import MainComplaint from './MainComplaint';
import CategoryPerformance from './CategoryPerformance';
import CategoryDescription from './CategoryDescription';

import { countAge } from 'utils/utils';

import styles from './index.less';

import moment from 'moment';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es',
};

const TrialSessionRecord = ({ location }) => {
  const intl = useIntl();

  const {
    subjectId,
    trainerId,
    type = 'download',
    culture,
    CognitiveleapUser,
    userTenantId,
    tenantId,
  } = location.query || {};

  const componentRef = useRef(null);

  const [assessmentData, setAssessmentData] = useState({});
  const [showFormData, setShowFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [trialData, subjectInfo, trainerInfo, categoryData] = showFormData;

  let mainComplaintData = {},
    trainingTargetData;

  const { questionnaireAnswer = {} } = assessmentData || {};

  const { answer, questionnaire } = questionnaireAnswer || {};

  const { answerContent } = answer || {};
  const { jsonString } = questionnaire || {};

  const feedbackData = resolveSurveyData(
    JSON.parse(answerContent || '{}'),
    JSON.parse(jsonString || '{}'),
    'allData',
  );

  if (feedbackData && feedbackData.length > 0) {
    const filterData = feedbackData.filter(
      (item) => item.name === 'mainComplaint' || item.name === 'trainingTarget',
    );
    if (filterData && filterData.length > 0) {
      mainComplaintData = filterData[0];
      trainingTargetData = filterData[1];
    }
  }

  const { value: mainComplaintValue = [] } = mainComplaintData || {}; // 主诉
  const { value: trainingTargetValue } = trainingTargetData || {}; // 训练目标

  const { creationTime } = trialData || {};
  const { name: userName, birthDay } = subjectInfo || {};
  const { name: trainerName } = trainerInfo || {};

  const initData = async () => {
    setLoading(true);

    if (getLocale() !== culture) {
      const res = await SetLanguage({ culture: LangOptions[culture] });
      if (res.response.ok) {
        setLocale(culture);
      }
    }

    if (CognitiveleapUser && userTenantId) {
      localStorage.setItem('CognitiveleapUser', CognitiveleapUser);
      localStorage.setItem('userTenantId', userTenantId);
      if (tenantId) localStorage.setItem('tenantId', tenantId);
    }

    const resList = await Promise.all([
      GetTrialPlan({ userId: subjectId }),
      CenterGetSubjectInfo(subjectId),
      GetTrainerDetail({ trainerId }),
      GetCategoryPerformance({ userId: subjectId }),
    ]);

    if (resList.every((res) => res.response.ok)) {
      setShowFormData(resList.map((item) => item.data));

      const trailData = resList[0].data || {};
      const { userAssessmentId: id } = trailData || {};
      if (id) {
        const assessmentRes = await GetAssessmentDetail(id);

        if (assessmentRes.response.ok) {
          setAssessmentData(assessmentRes.data || {});
        } else {
          const {
            data: { error },
          } = assessmentRes;
          message.error(error.message);
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'printTimetable' }),
          onClick: () => {
            hideMenu(_, contextMenu);
            setTimeout(() => {
              onClickDownLoad();
            }, 100);
          },
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ].filter((item) => item),
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  const onClickDownLoad = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => event.preventDefault());
    initCustomMenu();
    // 禁用浏览器快捷键打印功能
    window.addEventListener('keydown', function (e) {
      if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
        onClickDownLoad();
      }
    });
  }, []);

  const getTitleTag = (title, color, bgColor) => {
    return (
      <div className={styles.titleTag} style={{ color }}>
        <div className={styles.leftTag} style={{ backgroundColor: bgColor }}>
          [
        </div>
        <div className={styles.tagText}>{title}</div>
        <div className={styles.rightTag}>]</div>
      </div>
    );
  };

  const mainComplainProps = {
    mainComplaintValue,
    trainingTargetValue,
  };

  const categoryPerformanceProps = {
    trialData,
    assessmentData,
    categoryData,
  };

  const categoryDescriptionProps = {
    trialData,
  };

  return (
    <DocumentTitle
      title={`${intl.formatMessage({ id: 'HFS Baseline Skills' })}-${userName}`}
    >
      <>
        <Spin spinning={loading}>
          <div ref={componentRef} className={styles.breadboard}>
            <div className={styles.programTop}>
              <div className={styles.courseTime}>
                {moment(creationTime).format('MM/DD/YYYY')}
              </div>
              <hr />
              <div className={styles.programTitle}>
                {intl.formatMessage({ id: 'HFS Baseline Skills' })}
              </div>
              <div className={styles.birthday}>
                {userName} {countAge(birthDay, intl)}
              </div>
              <div className={styles.trainer}>
                {intl.formatMessage({ id: 'Trainer' })}: {trainerName}
              </div>
            </div>

            <div className={styles.programContent}>
              {mainComplaintValue?.length > 0 && (
                <div className={styles.parentalComplaints}>
                  {getTitleTag(
                    intl.formatMessage({ id: 'Parent Main Complaint' }),
                    '#F96B4B',
                    '#FFF1F1',
                  )}
                  <MainComplaint {...mainComplainProps} />
                </div>
              )}

              <div
                className={styles.categoryPerformance}
                style={
                  mainComplaintValue?.length > 0
                    ? { pageBreakBefore: 'always' }
                    : {}
                }
              >
                {getTitleTag(
                  intl.formatMessage({ id: 'Category Performance' }),
                  '#55B3B7',
                  '#E6F4F4',
                )}
                <CategoryPerformance {...categoryPerformanceProps} />
              </div>

              <div className={styles.categoryDescription}>
                {getTitleTag(
                  intl.formatMessage({ id: 'Category Description' }),
                  '#3C84FF',
                  '#E7EFFA',
                )}
                <CategoryDescription {...categoryDescriptionProps} />
              </div>
            </div>
          </div>
        </Spin>

        {type !== 'download' && (
          <div className={styles.download}>
            <Button onClick={onClickDownLoad}>
              {intl.formatMessage({ id: 'printTimetable' })}
            </Button>
          </div>
        )}
        <p />
      </>
    </DocumentTitle>
  );
};

export default TrialSessionRecord;
