import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import AnalysisChart from './charts/AnalysisChart';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

const CategoryAnalysisFirst = ({ categoryItem, pageNum }) => {
  const intl = useIntl();

  const {
    trainingCategory,
    categoryPerformance = [],
    feedback = [],
  } = categoryItem || {};

  const [performance1Data, performance2Data] = categoryPerformance || [];

  const { displayName } = trainingCategory || {};

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({ id: 'SkillDisplayName' }, { displayName }),
    headerLogo: '',
  };

  const xAxisData = performance1Data?.values?.map((item) => item.num) || [];
  const sessionsNum = xAxisData[xAxisData.length - 1];

  return (
    <div className={styles.page_div}>
      <div className={styles.categoryAnalysis}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom
            tagTitle={intl.formatMessage({ id: 'Category Performance' })}
            tagStyle={{ margin: '10px 0' }}
          />
          <div>
            <div>
              {intl.formatMessage(
                { id: 'two chartsDes' },
                { sessions: sessionsNum, category: displayName },
              )}
            </div>
            <div className={styles.analysisChart}>
              {categoryPerformance.map((item, index) => {
                const { feedbackProperty = {}, values = [] } = item;
                const showKey = `performance${index + 1}`;
                const chartProps = {
                  analysisValues: values,
                  showKey,
                  feedbackProperty,
                };
                return <AnalysisChart key={showKey} {...chartProps} />;
              })}
            </div>
          </div>

          <TagCom
            tagTitle={intl.formatMessage({ id: 'feedback' })}
            tagStyle={{ margin: '10px 0' }}
          />
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'four chartsDes' },
              { category: displayName },
            )}
          </div>
          <div className={styles.analysisChart} style={{ margin: '5px 0' }}>
            {feedback.map((item) => {
              const { feedbackProperty = {}, values = [] } = item;
              const { name } = feedbackProperty || {};
              const chartProps = {
                analysisValues: values,
                showKey: name,
                feedbackProperty,
              };
              return <AnalysisChart key={name} {...chartProps} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryAnalysisFirst);
