import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import arrowImg from 'assets/arrow.png';

const CategoryAnalysis = ({ hfsReportCategoryAnalysis, getCurrentPageNum }) => {
  const intl = useIntl();

  const toFixed = (num, decimal = 2) => {
    if (num === null || num === undefined) return 'N/A';
    if (!isNaN(num)) {
      return num.toFixed(decimal);
    }
    return num;
  };

  const headerContent = {
    pageNum: getCurrentPageNum(8),
    pageTitle: intl.formatMessage({ id: 'SkillOverall' }),
    headerLogo: '',
  };

  const showList =
    hfsReportCategoryAnalysis.map((item) => {
      const { trainingCategory } = item || {};
      return {
        title: 'Dexterity 1',
        value: '9',
        sessionNum: '(20 Sessions)',
      };
    }) || [];

  return (
    <div id="SkillBasedExerciseOverall" className={styles.page_div}>
      <div className={styles.categoryAnalysis}>
        <HeaderContent {...headerContent} />

        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl.formatMessage({ id: 'SkillOverall' }).toUpperCase()}
          </div>
          <TagCom
            tagTitle={intl.formatMessage({ id: 'Comparison of Difficulty' })}
          />
          <div className={styles.showList}>
            {hfsReportCategoryAnalysis.map((item) => {
              const {
                trainingCategory,
                minDifficulty,
                curDifficulty,
                sessionDif,
              } = item || {};
              const { displayName, id } = trainingCategory || {};

              return (
                <div className={styles.listItem} key={id}>
                  <div>
                    {displayName}: {minDifficulty || '-'}
                  </div>
                  <img src={arrowImg} />
                  <div>
                    <span className={styles.value}>{curDifficulty || '-'}</span>{' '}
                    {sessionDif > 0 && (
                      <span className={styles.sessionNum}>
                        ({sessionDif} {intl.formatMessage({ id: 'sessions' })})
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <TagCom
            tagTitle={intl.formatMessage({
              id: 'Performance Improvement Across',
            })}
          />

          <table style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'K' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'PerformanceOne' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'PerformanceTwo' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'Adherence to Activity Criteria' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'Confidence Level' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'Activity Completion' })}
                </th>
                <th style={{ width: '120px' }}>
                  {intl.formatMessage({ id: 'Level of Enjoyment' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {hfsReportCategoryAnalysis.map((item) => {
                const {
                  trainingCategory,
                  categoryPerformance = [],
                  feedback = [],
                } = item || {};

                const { displayName, id } = trainingCategory || {};

                const [performance1K, performance2K] =
                  categoryPerformance.map((item) => item.k) || [];
                const [adherenceK, confidenceK, completionK, enjoymentK] =
                  feedback.map((item) => item.k) || [];

                return (
                  <tr key={id}>
                    <th>{displayName}</th>
                    <td>{toFixed(performance1K)}</td>
                    <td>{toFixed(performance2K)}</td>

                    <td>{toFixed(adherenceK)}</td>
                    <td>{toFixed(confidenceK)}</td>
                    <td>{toFixed(completionK)}</td>
                    <td>{toFixed(enjoymentK)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={styles.tableDes}>
            {intl.formatMessage({ id: 'K ValueDes' })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryAnalysis);
