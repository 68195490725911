import React from 'react';
import { useIntl } from 'umi';
import ReactEcharts from 'echarts-for-react';

const CategoryCharts = ({ trainingCategoryPush = [] }) => {
  const intl = useIntl();

  const _trainingCategoryPush = [...trainingCategoryPush].reverse();

  let yAxisText = [],
    completionsData = [],
    pushesData = [];

  _trainingCategoryPush.forEach((item) => {
    const { category, numberOfCompletions, numberOfPushes } = item || {};
    const { displayName } = category || {};
    yAxisText.push(displayName);
    completionsData.push(numberOfCompletions);
    pushesData.push(numberOfPushes - numberOfCompletions);
  });

  const option = {
    color: ['#2b5b5d', '#8b9e9b'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      minInterval: 1,
    },
    yAxis: {
      type: 'category',
      data: yAxisText,
    },
    series: [
      {
        name: intl.formatMessage({ id: 'Number of Completions' }),
        type: 'bar',
        stack: 'total',
        barWidth: '18',
        data: completionsData,
      },
      {
        name: intl.formatMessage({ id: 'Number of Pushes' }),
        type: 'bar',
        stack: 'total',
        barWidth: '18',
        data: pushesData,
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      notMerge={true}
      opts={{ renderer: 'svg' }}
      style={{ height: '440px', width: '100%', margin: 'auto' }}
    />
  );
};

export default CategoryCharts;
