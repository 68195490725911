import React from 'react';
import { useIntl } from 'umi';
import ReactEcharts from 'echarts-for-react';
import { convertTimeToSecond } from 'cognitiveleap-core-us/utils/utils';
import { MyIntl } from 'cognitiveleap-core-us/utils/translateHelper';
import styles from './index.less';

const intl = MyIntl();

const BrainLineChart = ({ dataSource }) => {
  const _intl = useIntl();

  const { numProgress = [], currNum, endedNum } = dataSource;

  const _num = endedNum || currNum

  const getYConfig = () => {
    if (numProgress && numProgress.length > 0) {
      const webProgressList = numProgress.map((item) =>
        item.webCodeProgress.length > 0
          ? item.webCodeProgress
              .map((webItem) => convertTimeToSecond(webItem.progress))
              .reduce((pre, next) => pre + next)
          : 0,
      );
      const appProgressList = numProgress.map((item) =>
        item.appCodeProgress.length > 0
          ? item.appCodeProgress
              .map((appItem) => convertTimeToSecond(appItem.progress))
              .reduce((pre, next) => pre + next)
          : 0,
      );
      const webMax = Math.max(...webProgressList);
      const appMax = Math.max(...appProgressList);
      const totalMax = Math.max(webMax, appMax) / 60;
      if (totalMax < 10) return { max: 10, interval: 2 };
      else if (totalMax < 40) return { max: 40, interval: 10 };
      else return { max: 70, interval: 10 };
    } else {
      return { max: 10, interval: 2 };
    }
  };

  const xAxisData = Array(_num)
    .fill(0)
    .map((_, i) => i + 1);

  const option = {
    color: ['#2b5b5d', '#55b3b7'],
    title: {
      text: _intl.formatMessage({ id: 'HFS Listening Duration' }),
      left: 'center',
    },
    legend: {
      data: [
        intl('coachSideMusicPlayingTime'),
        intl('appSideMusicPlayingTime'),
      ],
      bottom: 0,
      selectedMode: false,
    },
    grid: {
      top: '14%',
      left: '2%',
      right: '2%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      nameGap: 4,
      nameTextStyle: {
        color: '#A8AEB8',
        fontSize: 12,
        padding: [20, 0, 0, 0],
      },
      name: _intl.formatMessage({ id: 'session' }),
      nameLocation: 'middle',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      name: intl('durationMinutesSeconds'),
      max: 70,
      interval: 10,
      nameTextStyle: {
        color: '#A8AEB8',
        fontSize: 10,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: (params) => {
          return params + ':00';
        },
        fontSize: 10,
      },
      splitLine: {
        lineStyle: {
          color: '#eee',
        },
      },
    },
    series: [
      {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
        name: intl('coachSideMusicPlayingTime'),
        data: [],
      },
      {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
        name: intl('appSideMusicPlayingTime'),
        data: [],
      },
    ],
  };

  const newData = {};
  numProgress &&
    numProgress
      // .sort((a, b) => b.num - a.num)
      .forEach((item, index) => {
        newData[index + 1] = {};
        newData[index + 1].web =
          item.webCodeProgress.length > 0
            ? item.webCodeProgress
                .map((webItem) => convertTimeToSecond(webItem.progress))
                .reduce((pre, next) => pre + next)
            : 0;
        newData[index + 1].app =
          item.appCodeProgress.length > 0
            ? item.appCodeProgress
                .map((appItem) => convertTimeToSecond(appItem.progress))
                .reduce((pre, next) => pre + next)
            : 0;
      });

  const webData = xAxisData.map((item) =>
    newData[item]
      ? newData[item].web / 60
      : item <= dataSource && _num
      ? 0
      : null,
  );
  const appData = xAxisData.map((item) =>
    newData[item]
      ? newData[item].app / 60
      : item <= dataSource && _num
      ? 0
      : null,
  );

  option.yAxis = { ...option.yAxis, ...getYConfig(dataSource) };

  option.series[0].data = webData;
  option.series[1].data = appData;

  const isEmpty =
    webData.reduce((pre, next) => pre + next) +
      appData.reduce((pre, next) => pre + next) ==
    0
      ? true
      : false;

  if (isEmpty) {
    option.series = [
      {
        type: 'line',
        name: intl('coachSideMusicPlayingTime'),
        data: [5],
        itemStyle: {
          opacity: 0,
        },
      },
      {
        type: 'line',
        name: intl('appSideMusicPlayingTime'),
        data: [5],
        itemStyle: {
          opacity: 0,
        },
      },
    ];
  }

  return (
    <div className={styles.brainLineChart}>
      <ReactEcharts
        option={option}
        notMerge={true}
        opts={{ renderer: 'svg' }}
        style={{ height: '490px', width: '100%', margin: 'auto' }}
      />
      {isEmpty && (
        <div className={styles.brainLineChartEmpty}>
          {_intl.formatMessage({ id: 'emptyData' })}
        </div>
      )}
    </div>
  );
};

export default BrainLineChart;
