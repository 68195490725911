import React from 'react';
import { useIntl } from 'umi';
import HeaderContent from './components/HeaderContent';
import VratOverallPerformanceChart from 'cognitiveleap-core-us/components/Charts/Archives/VratOverallPerformanceChart';
import VratAttentionDimension from 'cognitiveleap-core-us/components/Charts/Archives/VratAttentionDimension';
import TagCom from './components/TagCom';

import styles from './index.less';

const VcatStatisticsCharts = ({
  userInfo,
  vcatStatistical,
  getCurrentPageNum,
}) => {
  const intl = useIntl();

  const { OverViewAnalysis = [], RaderAnalysis = [] } = vcatStatistical;

  const headerContent = {
    pageNum: getCurrentPageNum(3),
    pageTitle: intl.formatMessage({ id: 'Assessment Statistics' }),
    headerLogo: '',
  };

  const overallProps = {
    userInfo,
    vratOverViewData: OverViewAnalysis,
    isLoading: false,
  };

  const radarProps = {
    userInfo,
    vratRadarData: RaderAnalysis,
    isLoading: false,
  };

  return (
    <div id="AssessmentStatistics" className={styles.page_div}>
      <div className={styles.vcatStatisticsCharts}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl.formatMessage({ id: 'Assessment Statistics' }).toUpperCase()}
          </div>
          <TagCom
            tagTitle={intl.formatMessage({
              id: 'Virtual Classroom Attention Charts',
            })}
          />
          <div className={styles.titleDes}>
            {intl.formatMessage({
              id: 'Virtual Classroom Attention ChartsDes',
            })}
          </div>

          <p />

          <div className={styles.chartsTitle}>
            {intl.formatMessage({ id: 'Overall Performance' })}
          </div>
          <VratOverallPerformanceChart {...overallProps} />

          <div className={styles.chartsTitle}>
            {intl.formatMessage({ id: 'Attention Dimension' })}
          </div>
          <VratAttentionDimension {...radarProps} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(VcatStatisticsCharts);
