import React from 'react';
import { useIntl, getLocale } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import NewFasChart from 'cognitiveleap-core-us/report/MFATwo_Grade_New/NewFasChart.jsx';

const FundamentalCharts = ({ fasReportData, getCurrentPageNum }) => {
  const intl = useIntl();
  const locale = getLocale();

  const { questionnaireData = [] } = fasReportData || {};

  const headerContent = {
    pageNum: getCurrentPageNum(5),
    pageTitle: intl.formatMessage({ id: 'Assessment Statistics' }),
    headerLogo: '',
  };

  const chartProps = {
    reportData:
      questionnaireData.length > 0 ? questionnaireData?.slice(0, 3) : {},
    locale,
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.fundamentalCharts}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom
            tagTitle={intl.formatMessage({
              id: 'Fundamental Abilities Survey Charts',
            })}
          />

          <NewFasChart {...chartProps} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(FundamentalCharts);
