import React, { useEffect } from 'react'
import { connect, getDvaApp, history, useIntl } from 'umi'
import styles from './index.less'
import { Table, Button, Popover, Row, Col, Badge, Modal, Tag, Tabs, DatePicker, Drawer, Spin, message } from 'antd'
import moment from 'moment';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { getTimeSlot, newConnectOneweek, move, eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import { getWeekday } from 'utils/utils'
import { LeftOutlined, RightOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import iconDetailPng from 'assets/icon_detail.png'
import iconEditGrayPng from 'assets/icon_edit_gray.png'
import iconDeletePng from 'assets/icon_delete.png'
import iconClosedPng from 'assets/icon_closed.png'
import iconTeacherPng from 'assets/icon_teacher.png'
import iconKidPng from 'assets/icon_kid.png'
import iconCenterPng from 'assets/icon_center.png'
import iconTime2Png from 'assets/icon_time2.png'
import iconRepetitionPng from 'assets/icon_repetition.png'
import iconCoursePng from 'assets/icon_course.png'
import tagOnlinePng from 'assets/tag_online.jpg'
import tagOfflinePng from 'assets/tag_offline.jpg'
import iconRepetition3Png from 'assets/icon_repetition3.jpg'

const { TabPane } = Tabs;

const modalTitle = (intl) => {
	return {
		"add": intl.formatMessage({ id: 'createSchedule' }),
		"nonRepetitive": intl.formatMessage({ id: 'editSingleSchedule' }),
		"repetitive": intl.formatMessage({ id: 'editMultipleSchedules' }),
	}
}

const planTypeEnum = (intl) => {
	return {
		"1": intl.formatMessage({ id: 'preschool' }),
		"2": intl.formatMessage({ id: 'gradeSchool' }),
		"3": intl.formatMessage({ id: 'teenage' }),
		"4": intl.formatMessage({ id: 'adult' }),
	}
}

// 当前的时间区间 {startTime: 8:00, endTime: 8:30}
const timeSlot = (selectDate) => getTimeSlot(8, 21, 5, selectDate)

const getRepeatDescription = (periodIntervalUnit, periodIntervalNumber, startTime, endTime, intl) => {
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 1) {
		// return `每天${moment(startTime).format("HH:mm")} - ${moment(endTime).format("HH:mm")}重复`
		return intl.formatMessage({ id: 'everyday' })
	}
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 7) {
		return intl.formatMessage({ id: 'repeatEveryDay' }, { day: getWeekday(moment(startTime).day(), intl) })
	}
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 14) {
		return intl.formatMessage({ id: 'repeatFortnightly' }, { day: getWeekday(moment(startTime).day(), intl) })
	}
	if (periodIntervalUnit === "Month" && periodIntervalNumber === 1) {
		return intl.formatMessage({ id: 'RepeatEachMonth' }, { day: moment(startTime).format("DD") })
	}
	return ""
}

const oneselfListSupport = (intl) => {
	return {
		displayName: {
			showText: intl.formatMessage({ id: 'onlyCenter' }),
			showType: 'Text'
		},
		costNum: {
			showText: intl.formatMessage({ id: 'usedSessions' }),
			showType: 'Text'
		}
	}
}

const centerListSupport = (intl) => {
	return {
		trainerName: {
			showText: intl.formatMessage({ id: 'trainer' }),
			showType: 'Text',
			render(text, record, index) {
				const id = record && record.trainer && record.trainer.id
				return (
					record.trainer ?
						<a onClick={() => {
							const url = `/system/center/detail/groupDetail?id=${id}`
							history.push(url)
						}}>
							{text}
						</a>
						:
						<span>{text}</span>
				)
			}
		},
		costNum: {
			showText: intl.formatMessage({ id: 'usedSessions' }),
			showType: 'Text',
		}
	}	
}

const myTag = (type, intl) => {
	let context = intl.formatMessage({ id: type === "Online" ? "online" : "offline" })
	let backgroundColor = type === "Online" ? "#55B3B7" : "#3C84FF"
	return (
		<div
			className={styles.myTag}
			style={{ backgroundColor }}
		>
			{context}
		</div>
	)
}

let timer = null

/**
 * 8:00~8:30   1
 * 
 */
const ClassCardDetail = ({ dispatch, classCard, loading, currentUser }) => {

	const intl = useIntl()

	const { auth } = currentUser || {}
	const { grantedPolicies } = auth || {}
	const hasReadOneselfPermission = grantedPolicies["Business.Calendar.Read"]
	const hasReadOtherPermission = grantedPolicies["Business.Calendar.ReadOther"]
	const hasChildrenListPermission = grantedPolicies["Business.SubjectManagement"]
	const hasTeamManagePermission = grantedPolicies["Business.TenantUserManagement"]
	const hasTeamManageMenuPermission = grantedPolicies["Menu.OperationalManagement.EmployeeManagement"]
	const hasWriteOneselfPermission = grantedPolicies["Business.Calendar.Write"]
	const hasWriteOtherPermission = grantedPolicies["Business.Calendar.WriteOther"]

	/**
	 * 给id为classCardHoverInfo添加拖拽效果
	 * 修改当前currentDate
	 */
	useEffect(() => {
		const { auth } = currentUser || {}
		const { grantedPolicies } = auth || {}
		const hasReadOneselfPermission = grantedPolicies["Business.Calendar.Read"]
		const hasReadOtherPermission = grantedPolicies["Business.Calendar.ReadOther"]
		if (hasReadOneselfPermission || hasReadOtherPermission) {
			if (hasReadOneselfPermission) {
				dispatch({ type: 'classCard/updateState', payload: { content: null, type: 'oneself' } })
			} else if (hasReadOtherPermission) {
				dispatch({ type: 'classCard/updateState', payload: { content: null, type: 'center' } })
			}
			dispatch({ type: "classCard/query" })
		}

		move('classCardHoverInfo')
		timer = setInterval(() => {
			// 刷新data
			dispatch({
				type: 'classCard/updateStateData',
			})
			// dispatch({
			// 	type: 'classCard/updateState',
			// 	payload: {
			// 		currentDate: moment(),
			// 	}
			// })
		}, 30 * 1000)
		return () => clearInterval(timer)
	}, [])

	const addItemToColumnData = (item, columnData) => {
		if (columnData.length > 0) {
			//如果能直接放入原来的数据里就直接放进去
			const isSuccessPush = columnData.some(columnDataItem => {
				//如果新数据跟每一个都可以不重叠，则直接添加进去
				if (columnDataItem.every(calendarItem => {
					return moment(calendarItem.startTime).isSameOrAfter(item.endTime) || moment(calendarItem.endTime).isSameOrBefore(item.startTime)
				})) {
					columnDataItem.push(item)
					return true
				}
			})
			if (!isSuccessPush) {
				columnData.push([])
				columnData[columnData.length - 1].push(item)
			}
		}
		else {
			columnData.push([])
			columnData[0].push(item)
		}
	}

	const getHours = (time) => {
		return moment(time).hour()
	}

	const getMinutes = (time) => {
		return moment(time).minute()
	}

	const filterData = (startTime, endTime, columnList) => {
		const findData = columnList.find(item => {

			const isSameOrBefore = getHours(item.startTime) < getHours(startTime) ||
				(getHours(item.startTime) === getHours(startTime) && getMinutes(item.startTime) <= getMinutes(startTime))

			const isSameOrAfter = getHours(item.endTime) > getHours(endTime) ||
				(getHours(item.endTime) === getHours(endTime) && getMinutes(item.endTime) >= getMinutes(endTime))
			//const isBefore = moment(moment(item.startTime).format('HH:mm')).isSameOrBefore(moment(moment(startTime).format('HH:mm')), 'minutes')
			//const isAfter = moment(moment(item.endTime).format('HH:mm')).isSameOrAfter(moment(moment(endTime).format('HH:mm')), 'minutes')
			return isSameOrBefore && isSameOrAfter

		})
		if (findData !== undefined) {
			const totalTimeSpan = moment(findData.endTime).diff(moment(findData.startTime), 'minutes')
			const unit = moment(endTime).diff(moment(startTime), 'minutes')
			const beforeTimeSpan = getHours(startTime) * 60 + getMinutes(startTime) - getHours(findData.startTime) * 60 - getMinutes(findData.startTime)

			return {
				...findData,
				cardIndex: beforeTimeSpan / unit,
				total: totalTimeSpan / unit,
			}
		}
	}

	const filterRowData = (startTime, endTime, columnAllData) => {
		let result = {}
		Object.keys(columnAllData).forEach(keyItem => {
			result[keyItem] = columnAllData[keyItem].map(columnItemList => {
				return filterData(startTime, endTime, columnItemList)
			})
		})
		return result
	}

	const {
		currentDate,
		startDate,
		endDate,
		content,
		editArrangeVisible,
		isShowHoverInfo,
		top,
		left,
		hoverDataInfo,
		showDrawer,
		type,
		selfChangeDate,
		centerChangeDate,
		modalType,
		data,
		deleteModalVisible,
		deleteContent,
		oneselfCourseNumData,
		centerCourseNumData,
		currentTrainerArr,
		changeTrainerIndex,
		trainerList,
		isShowPreData
	} = classCard || {}


	const {
		calendarCount,
		calendarCourseType,
		tenantInfo,
		startTime,
		endTime,
		periodIntervalUnit,
		periodIntervalNumber,
		students,
		currentItemStartTime,
		currentItemEndTime,
		executor,
		isRealItem,
		trainingCourseNum,
		trainingType,
		userTrainingPlanId,
		backendStartTime
	} = hoverDataInfo || {}

	// 本人的总耗课数
	const oneselfCourseNum = oneselfCourseNumData ? oneselfCourseNumData.reduce((result, next) => result + next.costNum, 0) : 0
	// 本中心的总耗课数
	const centerCourseNum = centerCourseNumData ? centerCourseNumData.reduce((result, next) => result + next.costNum, 0) : 0

	const totalCourseNum = type === 'oneself' ? oneselfCourseNum : centerCourseNum

	const oneweekDate = newConnectOneweek(startDate, endDate)

	const changeTrainLeftDisabled = changeTrainerIndex === 0
	const changeTrainRightDisabled = Math.ceil(trainerList.length / 7) === changeTrainerIndex + 1;

	// 写的权限
	const hasWritePermissionFn = () => {
		if (type === "oneself") {
			return hasWriteOneselfPermission
		}
		if (type === "center") {
			return hasWriteOtherPermission
		}
	}

	const hasWritePermission = hasWritePermissionFn()

	// 添加课程
	const addCourse = (text, row, index, col) => {
		if (hasWritePermission) {
			const { startTime } = row
			const payload = {
				col,
				time: moment(startTime).format("HH:mm"),
				intl
			}
			dispatch({ type: 'classCard/addCourse', payload })
		} else {
			message.error(intl.formatMessage({ id: 'youNotPermission' }))
		}
	}

	/**
	 * e.clientY + document.documentElement.scrollTop 这是当前点击位置的高度
	 */
	const showHoverInfo = (e, item, text, row, index, col) => {
		let top = e.clientY + document.documentElement.scrollTop - 100
		let left = e.clientX
		if (left + 360 > document.body.clientWidth) {
			left = document.body.clientWidth - 530
		}
		e.stopPropagation()

		dispatch({
			type: "classCard/getPlanDetailInfo",
			payload: {
				// isShowHoverInfo: true,
				top: `${top}px`,
				left: `${left}px`,
				dataInfo: { item, text, row, index, col },
				selectCalendar: item
			}
		})
		// dispatch({
		// 	type: 'classCard/updateState',
		// 	payload: {
		// 		isShowHoverInfo: true,
		// 		top: `${top}px`,
		// 		left: `${left}px`,
		// 		hoverDataInfo: { item, text, row, index, col }
		// 	}
		// })
	}

	const getCenterLinePercent = () => {
		if (type === 'center') {
			const timeSlotList = timeSlot(centerChangeDate)
			const startTime = timeSlotList[0].startTime
			const endTime = timeSlotList[timeSlotList.length - 1].endTime
			if (moment(startTime).isBefore(currentDate) && moment(endTime).isAfter(currentDate)) {
				const percent = currentDate.diff(moment(startTime)) / moment(endTime).diff(moment(startTime))
				return (
					<div style={{ width: '100%', height: '1px', background: '#3C84FF', position: 'absolute', top: `${102 + 60 + percent * (1118 + 14)}px`, left: 0, zIndex: 1 }}>
						<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#3C84FF', position: 'absolute', left: -3, bottom: -3 }}></div>
					</div>
				)
			}
			else {
				return <span></span>
			}
		}
		else {
			return <span></span>
		}
	}


	const getWidthTimes = (item, conflictInfo, index, totalCount) => {
		const { userPlanId, currentIndex, trainingCourseNum, userTrainingPlanId, isRealItem } = item
		const useId = isRealItem ? userTrainingPlanId + trainingCourseNum : userPlanId
		if (conflictInfo.hasOwnProperty(useId + currentIndex)) {
			const info = conflictInfo[useId + currentIndex]
			let times = 1
			let i = index
			while (!info.includes(i + 1) && i < totalCount - 1) {
				times++
				i++
			}
			return times
		}
		else {
			return totalCount - index
		}
	}

	const getShowText = (text, conflictInfo) => {
		const totalCount = text.length
		let result = []
		let times = 0
		let isBeforeData = true
		text.forEach((item, index) => {
			if (item === undefined) {
				if (isBeforeData) {
					result.push(item)
				}
				else {
					if (times > 0) {
						times--
					}
					else {
						result.push(item)
					}
				}
			}
			else {
				const number = getWidthTimes(item, conflictInfo, index, totalCount)
				const width = (type === 'center') && currentTrainerArr && currentTrainerArr.length > 7 ? `${200 * (number / (totalCount || 1)) - 6}px` : `calc(${number / (totalCount || 1) * 100}% - 6px)`
				times = number - 1
				isBeforeData = false
				result.push({
					...item,
					itemWidth: width
				})
			}
		})
		return result
	}

	const render = (text, row, index, col, conflictInfo) => {
		const { startTime, endTime } = row
		const isCurrentDate = type === "oneself" ? moment(col).isSame(currentDate, 'd') : moment(startTime).isSame(currentDate, 'd');
		const isInCurrentCard = isCurrentDate && moment(startTime).isBefore(currentDate) && moment(endTime).isAfter(currentDate)
		const percent = isInCurrentCard && currentDate.diff(moment(startTime)) / moment(endTime).diff(moment(startTime))
		const lineHeight = `${percent * 7.17}px`
		const initCount = text.length
		const lineWidth = (type === 'center') && currentTrainerArr && currentTrainerArr.length > 7 ? '200px' : '100%'
		const width = (type === 'center') && currentTrainerArr && currentTrainerArr.length > 7 ? `${200 * (1 / (initCount || 1)) - 6}px` : `calc(${1 / (initCount || 1) * 100}% - 6px)`
		const line = percent && type !== 'center' ? (
			<div style={{ width: lineWidth, height: '1px', background: '#3C84FF', position: 'absolute', top: lineHeight, left: 0, zIndex: 1 }}>
				<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#3C84FF', position: 'absolute', left: -3, bottom: -3 }}></div>
			</div>
		) : null

		//先去掉中间的一些undefined
		let showText = getShowText(text, conflictInfo)

		//如果text里包含有数据，则操作一波去掉后面的全部undefined
		if (showText.some(item => item !== undefined)) {
			//判断原数据后面有多少个undefined
			showText.reverse()
			const undefinedCount = showText.findIndex(item => item !== undefined)
			showText = showText.slice(undefinedCount)
			showText.reverse()
		}
		const isPointer = type === "oneself" ? moment(`${col} ${moment(startTime).format("HH:mm")}`).isAfter(currentDate) : moment(`${moment(centerChangeDate).format("YYYY/MM/DD")} ${moment(startTime).format("HH:mm")}`).isAfter(currentDate)

		return (
			<div
				style={{ cursor: isPointer ? 'pointer' : '' }}
				className={styles.row}
				onDoubleClick={() => addCourse(text, row, index, col)}
			>
				{
					initCount > 0 ? showText.map((item, dataIndex) => {
						const { tenantInfo, students, calendarCourseType, startTime, isRealItem, itemWidth, cardIndex, total = 0, trainingCourseNum, trainingType, totalCount } = item || {}
						if (item !== undefined) {
							const endTime = moment(startTime).add(1, 'hours')
							const backgroundColor = isRealItem ? '#F7F7F7' : (moment(endTime).isBefore(currentDate) ? '#F7F7F7' : '#E6F7FF')
							const color = moment(endTime).isBefore(currentDate) ? 'rgba(0, 0, 0, 0.45)' : 'rgba(0, 0, 0, 0.85)'
							return (
								<div
									key={dataIndex}
									onClick={(e) => showHoverInfo(e, item, text, row, index, col)}
									className={styles.rowItem}
									style={{
										background: backgroundColor,
										width: itemWidth,
										position: 'relative',
										/*marginBottom: cardIndex === total - 1 ? '1px' : '0',*/
										cursor: 'pointer',
										color,
									}}
								>
									<div
										style={{
											// height: '47px',
											background: backgroundColor,
											width: "100%"
										}}
										// onClick={(e) => editCourse(e, 'Repetitive', item, text, row, index, col)}
										className={styles.rowItemContent}
									>
										{
											totalCount !== 0 && item.cardIndex === 0 &&
											<img src={iconRepetition3Png} style={{ width: '12px', position: 'absolute', right: '4px', top: '4px', zIndex: '1' }} />
										}
										{
											item.cardIndex === 0 && students && students.length > 0 &&
											<div className={styles.rowItemContentTop}>
												<div
													className={styles.rowItemContentTopName}
												>
													{students.map(item => item && item.name).join(",")}
												</div>

												{
												 	total > 6 &&
													<div className={styles.rowItemContentBottomRight}>
														{
															calendarCourseType &&
															<div>
																{
																	calendarCourseType === "Online" ?
																		<React.Fragment>{myTag("Online", intl)}</React.Fragment> :
																		<React.Fragment>{myTag("Offline", intl)}</React.Fragment>
																}
															</div>
														}
														{
															isRealItem &&
															<span className={styles.rowItemContentBottomRightDes}>{planTypeEnum(intl)[trainingType] || ""}  {intl.formatMessage({ id : 'whichSession' }, { num: trainingCourseNum })}</span>
														}
													</div>
												}
											</div>
										}
										{
											item.cardIndex === 6 && total > 10 &&
											<div className={styles.rowItemContentBottom}>
												<div className={styles.rowItemContentTopCenterName}>
													{tenantInfo && tenantInfo.displayName}
												</div>
											</div>
										}
									</div>
								</div>
							)
						} else {
							return (
								<div
									key={dataIndex}
									style={{ width, position: 'relative', marginRight: '6px' }}
								>
								</div>
							)
						}
					}) : (
						<div
							style={{ width, position: 'relative', marginRight: '6px' }}
						>
						</div>
					)
				}
				{line}
			</div>
		)
	}

	const getColumnKeys = () => {
		if (type === 'oneself') {
			return oneweekDate
		}
		else {
			return currentTrainerArr && currentTrainerArr.map(item => item.id)
		}
	}

	const getColumns = (conflictInfo) => {
		let columns = []
		if (type === "oneself") {
			columns = oneweekDate.map((item, index) => {
				const isCurrentDate = moment(currentDate).format('YYYY/MM/DD') === moment(item).format('YYYY/MM/DD')
				return {
					title: (
						<div className={styles.tableHead}>
							<div className={`${styles.tableHeadTitle} ${isCurrentDate && styles.currentTableHeadTitle}`}>
								{`${getWeekday(moment(item).day(), intl)}`}
							</div>
							<div className={`${styles.tableHeadDay} ${isCurrentDate && styles.currentTableHeadDay}`}>
								{moment(item).format('DD')}
							</div>
						</div>
					),
					dataIndex: item,
					key: item,
					width: `${1 / (oneweekDate.length) * 100}%`,
					render: (text, row, index) => render(text, row, index, item, conflictInfo)
				}
			})
		} else {
			const width = currentTrainerArr && currentTrainerArr.length > 7 ? '200px' : `${1 / (currentTrainerArr.length) * 100}%`
			columns = currentTrainerArr && currentTrainerArr.map((item, index) => {
				return {
					title: (
						<div className={styles.tableHead}>
							{
								type === "center" && currentTrainerArr && currentTrainerArr.length > 0 && index === 0 &&
								<div
									className={styles.tableHeadChangeLeft}
									onClick={() => !changeTrainLeftDisabled && dispatch({ type: 'classCard/changeTrain', payload: { type: 'left' } })}
								>
									<LeftOutlined
										style={{ color: changeTrainLeftDisabled ? '#999' : 'black', position: 'absolute', top: '52px', left: '10px', fontSize: '18px' }}
									/>
								</div>
							}
							<div
								className={styles.tableHeadTrainerName}
								onClick={() => {
									const url = `/system/center/detail/groupDetail?id=${item && item.id}`
									history.push(url)
								}}
							>
								{item.name || item.email}
							</div>
							{
								type === "center" && currentTrainerArr && currentTrainerArr.length > 0 && index === currentTrainerArr.length - 1 &&
								<div
									className={styles.tableHeadChangeRight}
									onClick={() => !changeTrainRightDisabled && dispatch({ type: 'classCard/changeTrain', payload: { type: 'right' } })}
								>
									<RightOutlined
										style={{ color: changeTrainRightDisabled ? '#999' : 'black', position: 'absolute', top: '52px', right: '0', fontSize: '18px' }}
									/>
								</div>
							}
						</div>
					),
					key: item.id,
					dataIndex: item.id,
					width,
					render: (text, row, index) => render(text, row, index, item.id, conflictInfo)
				}
			})
		}
		return columns
	}

	const getConflictInfo = (data, keys) => {
		let result = {}
		data.forEach(dataItem => {
			keys.forEach(keyItem => {
				const unitList = dataItem[keyItem].filter(item => item !== undefined)
				if (unitList.length > 1) {
					const allNotUndefined = getListNotUndefinedIndexList(dataItem[keyItem])
					dataItem[keyItem].forEach((item, index) => {
						if (item !== undefined) {
							const { userPlanId, currentIndex, trainingCourseNum, userTrainingPlanId, isRealItem } = item
							let useId = isRealItem ? userTrainingPlanId + trainingCourseNum : userPlanId

							if (result.hasOwnProperty(useId + currentIndex)) {
								result[useId + currentIndex] = [...new Set(result[useId + currentIndex].concat(getNotIncludeData(allNotUndefined, index)))]
							}
							else {
								result[useId + currentIndex] = getNotIncludeData(allNotUndefined, index)
							}
						}
					})
				}
			})
		})
		return result
	}

	const getNotIncludeData = (indexList, notIncludeIndex) => {
		let result = []
		indexList.forEach(item => {
			if (item !== notIncludeIndex) result.push(item)
		})
		return result
	}

	const getListNotUndefinedIndexList = (list) => {
		let result = []
		list.forEach((item, index) => {
			if (item !== undefined) {
				result.push(index)
			}
		})
		return result
	}

	const getDataSource = () => {
		let columnFilteredObj = {}

		const backendData = data.map(item => {
			const { startTime, endTime, isRealItem } = item || {}
			let _endTime;
			if (moment(startTime).isSame(moment(endTime))) {
				_endTime = moment(startTime).add(1, 'hours').format()
			} else {
				_endTime = moment(endTime).format()
			}
			// const realEndTime = isRealItem ? moment(startTime).add(1, 'hours').format() : moment(endTime).format()
			return {
				...item,
				startTime: moment(startTime).format(),
				endTime: _endTime
			}
		})

		if (type == 'oneself') {
			oneweekDate.forEach(oneweekDateelement => {
				const filteredData = backendData.filter(backendDataitem => moment(backendDataitem.startTime).isSame(oneweekDateelement, 'day'))
				let columnData = []
				if (filteredData.length > 0)
					filteredData.forEach(filteredDataitem => {
						addItemToColumnData(filteredDataitem, columnData)
					})
				columnFilteredObj[oneweekDateelement] = columnData
			})
		}
		else {
			currentTrainerArr.forEach(trainerItem => {
				const filteredData = backendData.filter(backendDataitem => backendDataitem.executor && backendDataitem.executor.id === trainerItem.id)
				let columnData = []
				if (filteredData.length > 0)
					filteredData.forEach(filteredDataitem => {
						addItemToColumnData(filteredDataitem, columnData)
					})
				columnFilteredObj[trainerItem.id] = columnData
			})
		}

		const showData = timeSlot(centerChangeDate).map(timeSlotItem => {
			return {
				...timeSlotItem,
				...filterRowData(timeSlotItem.startTime, timeSlotItem.endTime, columnFilteredObj)
			}
		})
		return showData
	}

	const modalProps = {
		title: modalTitle(intl)[modalType],
		visible: editArrangeVisible,
		loading: loading.models.classCard,
		content,
		onCancel() {
			dispatch({ type: 'classCard/updateState', payload: { editArrangeVisible: false } })
		},
		onOk(value) {
			eventDebounce(() => {
				if (moment(value.startTime).format('LT') == "20:30") {
					message.error(intl.formatMessage({ id: 'chooseTheCourseAsTheLatest' }), 3)
					return;
				}
				dispatch({ type: "classCard/submitCourse", payload: { value, intl } })
			}, 300)
		}
	}

	const deleteModalProps = {
		title: <b style={{ color: 'red' }}>{intl.formatMessage({ id: 'delete' })}</b>,
		visible: deleteModalVisible,
		content: deleteContent,
		onCancel() {
			dispatch({ type: 'classCard/updateState', payload: { deleteModalVisible: false } })
		},
		onOk(value) {
			dispatch({ type: "classCard/onDeleteModal", payload: { value, intl } })
		}
	}

	const TableProps = {
		listData: type === "oneself" ?
			(
				oneselfCourseNumData ? oneselfCourseNumData.map(item => {
					return {
						...item,
						displayName: item.tenantDto && item.tenantDto.displayName,
						id: item.tenantDto && item.tenantDto.id
					}
				})
					:
					[]
			)
			:
			(
				centerCourseNumData ? centerCourseNumData.map(item => {
					return {
						...item,
						trainerName: item.trainer ? item.trainer.name : intl.formatMessage({ id: 'unknownCoach' }),
						id: item.trainer && item.trainer.id
					}
				})
					:
					[]
			),
		listSupport: type === "oneself" ? oneselfListSupport(intl) : centerListSupport(intl)
	}

	const onDeleteHoverInfo = () => {
		dispatch({ type: 'classCard/onDelete', payload: { intl }})
	}

	const onchangeTab = (tab) => {
		dispatch({ type: "classCard/onchangeTab", payload: tab })
	}

	const cardIsEnded = isRealItem ? true : moment(currentItemStartTime).add(1, 'hours').isBefore(currentDate)
	const isCurrentCenterDate = moment(currentDate).format("YYYY/MM/DD") === moment(centerChangeDate).format("YYYY/MM/DD")

	const showData = getDataSource()
	const conflictInfo = getConflictInfo(showData, getColumnKeys())
	const columns = getColumns(conflictInfo)

	return (
		<div
			className={styles.classCard}
			onClick={() => dispatch({ type: 'classCard/updateState', payload: { isShowHoverInfo: false } })}
		>
			<div className={styles.classCardHeader}>
				<div className={styles.classCardHeaderTop}>
					<div className={styles.classCardHeaderLeft}>
						<Button onClick={() => history.goBack()}>{intl.formatMessage({ id: 'back' })}</Button>
						<span className={styles.classCardHeaderLeftTitle}>{intl.formatMessage({ id: 'timetable' })}</span>
					</div>
					<div className={styles.classCardHeaderRight}>	
						{
							hasTeamManagePermission && hasTeamManageMenuPermission && type === "center" &&
							<a onClick={() => history.push("/system/center/menu/manage/group")}>{intl.formatMessage({ id: 'teamManagement' })}</a>
						}
						{
							hasChildrenListPermission &&
							<a onClick={() => history.push("/system/center/menu/user/subject")}>{intl.formatMessage({ id: 'subjectList' })}</a>
						}
					</div>
				</div>
				{
					(hasReadOneselfPermission || hasReadOtherPermission) &&
					<div className={styles.classCardHeaderBottom}>
						<Tabs activeKey={type} onChange={onchangeTab}>
							{
								hasReadOneselfPermission &&
								<TabPane tab={intl.formatMessage({ id: 'personal' })} key="oneself"></TabPane>
							}
							{
								hasReadOtherPermission &&
								<TabPane tab={intl.formatMessage({ id: 'thisCenter' })} key="center"></TabPane>
							}
						</Tabs>
					</div>
				}
			</div>
			{
				(hasReadOneselfPermission || hasReadOtherPermission) &&
				<React.Fragment>
					<div className={styles.classCardHks}>
						<div className={styles.classCardHksContent}>
							<div className={styles.classCardHksContentLeft}>{intl.formatMessage({ id: 'totalUsedSessions' })} {totalCourseNum}</div>
							<div className={styles.classCardHksContentRight} onClick={() => dispatch({ type: 'classCard/showCourses' })}>{intl.formatMessage({ id: 'detail' })}</div>
						</div>
					</div>
					<div className={styles.classCardContent}>
						<div className={styles.classCardContentTop}>
							<div className={styles.classCardContentTopLeft}>
								<div className={styles.classCardContentTopItem}>
									<Button
										disabled={
											type === "oneself" ?
												moment(selfChangeDate).format('YYYY/MM/DD') === moment(currentDate).format('YYYY/MM/DD')
												:
												moment(centerChangeDate).format('YYYY/MM/DD') === moment(currentDate).format('YYYY/MM/DD')
										}
										onClick={() => dispatch({ type: 'classCard/changeDateToToday' })}
									>
										{intl.formatMessage({ id: 'today' })}
									</Button>
								</div>
								<div className={styles.classCardContentTopItem}>
									<LeftOutlined
										onClick={() => dispatch({ type: "classCard/transDate", payload: 'subtract' })}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									<RightOutlined
										onClick={() => dispatch({ type: "classCard/transDate", payload: 'add' })}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									<DatePicker
										placeholder="mm/dd/yyyy"
										format={'MM/DD/YYYY'}
										value={type === "oneself" ? selfChangeDate : centerChangeDate}
										onChange={val => dispatch({ type: 'classCard/changeDate', payload: val })}
										allowClear={false}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									{
										isShowPreData ?
											<span style={{ color: '#1890FF' }} onClick={() => dispatch({ type: 'classCard/showPreData', payload: false })}>
												<span style={{ marginRight: '10px' }}>{intl.formatMessage({ id: 'previousClass' })}</span>
												<CheckOutlined />
											</span>
											:
											<span onClick={() => dispatch({ type: 'classCard/showPreData', payload: true })}>{intl.formatMessage({ id: 'previousClass' })}</span>
									}
								</div>
							</div>
							<div className={styles.classCardContentTopRight}>
								<Button icon={<PlusOutlined />} onClick={() => dispatch({ type: 'classCard/addCourse' })}>{intl.formatMessage({ id: 'scheduleSession' })}</Button>
							</div>
						</div>
						<div className={styles.classCardContentBottom} style={{ marginRight: type === "center" ? "25px" : "0" }}>
							<div className={styles.classCardContentLeft}>
								<div className={styles.classCardContentLeftContent} style={{ top: type === "center" ? "150px" : "84px" }}>
									{
										timeSlot(centerChangeDate).map((item, key) => {
											const { startTime } = item || {}
											return (
												key % 12 === 0 ?
													<div className={styles.classCardContentLeftTimeLine} key={key}>{moment(startTime).format('HH:mm')}</div>
													:
													<div className={styles.classCardContentLeftTimeLine} key={key}></div>
											)
										})
									}
									<div className={styles.classCardContentLeftTimeLine}>21:00</div>
								</div>
							</div>
							<div className={styles.classCardContentRight}>
								{
									type === "center" &&
									<div
										className={styles.tableHeadTop}
									>
										<div className={`${isCurrentCenterDate && styles.currentTableHeadTopTitle}`}>{`${getWeekday(moment(centerChangeDate).day(), intl)}`}</div>
										<div className={`${styles.tableHeadTopDay} ${isCurrentCenterDate && styles.currentTableHeadTopDay}`}>{moment(centerChangeDate).format("DD")}</div>
									</div>
								}
								{/* {
									type === "center" && currentTrainerArr && currentTrainerArr.length > 0 &&
									<div className={styles.changeTrainer}>
										<div
											className={styles.changeTrainerLeft}
											onClick={() => !changeTrainLeftDisabled && dispatch({ type: 'classCard/changeTrain', payload: { type: 'left' } })}
										>
											<LeftOutlined
												style={{ color: changeTrainLeftDisabled ? '#999' : 'black' }}
											/>
										</div>
										<div
											className={styles.changeTrainerRight}
											onClick={() => !changeTrainRightDisabled && dispatch({ type: 'classCard/changeTrain', payload: { type: 'right' } })}
										>
											<RightOutlined
												style={{ color: changeTrainRightDisabled ? '#999' : 'black' }}
											/>
										</div>
									</div>
								} */}
								<Table
									rowKey={'startTime'}
									columns={columns}
									dataSource={showData}
									pagination={false}
									bordered={true}
									tableLayout={"fixed"}
								// loading={loading.models.classCard}
								/>
								{
									getCenterLinePercent()
								}
							</div>
						</div>
					</div>
				</React.Fragment>
			}
			{/* 展示单个课程的hover信息 */}
			<div
				className={styles.classCardHoverInfo}
				style={{
					top: top,
					left: left,
					display: isShowHoverInfo ? 'inline-block' : 'none',
					background: cardIsEnded ? (isRealItem ? '#F7F7F7' : '#F5F9FF') : '#FFF',
					zIndex: 300
				}}
				id='classCardHoverInfo'
			>
				<div className={styles.classCardHoverInfoTop}>
					<div className={styles.classCardHoverInfoTopLeft}>
						{
							cardIsEnded ?
								(
									isRealItem ?
										<React.Fragment>
											<Badge color="#3C84FF" />
											<span>{intl.formatMessage({ id: 'Terminated' })}</span>
										</React.Fragment>
										:
										<React.Fragment>
											<Badge color="#95BBF4" />
											<span>{intl.formatMessage({ id: 'past' })}</span>
										</React.Fragment>
								)
								:
								<span></span>
						}
					</div>
					<div className={styles.classCardHoverInfoTopRight}>
						{
							cardIsEnded ?
								<React.Fragment>
									<img
										src={iconDetailPng}
										onClick={() => {
											let url = ""
											if (isRealItem) {
												if (userTrainingPlanId) {
													url = `/system/center/detail/userOfflinePlanDetail?id=${userTrainingPlanId}`
												}
											} else {
												if (students && students.length > 0) {
													url = `/system/center/detail/subjectDetail?id=${students[0].id}`
												}
											}
											if (url)
												dispatch({
													type: 'classCard/changeRoute',
													payload: {
														url
													}
												})
										}}
									/>
									<img src={iconClosedPng} onClick={() => dispatch({ type: 'classCard/onClosed' })} />
								</React.Fragment>
								:
								<React.Fragment>
									{
										hasWritePermission &&
										<React.Fragment>
											<img src={iconEditGrayPng} onClick={() => dispatch({ type: 'classCard/editCourse', payload: { intl }})} />
											<img
												src={iconDetailPng}
												onClick={() => {
													let url = ""
													if (userTrainingPlanId) {
														url = `/system/center/detail/userOfflinePlanDetail?id=${userTrainingPlanId}`
													} else if (students && students.length > 0) {
														url = `/system/center/detail/subjectDetail?id=${students[0].id}`
													}

													if (url)
														dispatch({
															type: 'classCard/changeRoute',
															payload: {
																url
															}
														})
												}}
											/>
											<img src={iconDeletePng} onClick={onDeleteHoverInfo} />
										</React.Fragment>
									}
									<img src={iconClosedPng} onClick={() => dispatch({ type: 'classCard/onClosed' })} />
								</React.Fragment>
						}
					</div>
				</div>
				<div className={styles.classCardHoverInfoBottom}>
					{/* 训练师 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconTeacherPng} />
						<div className={styles.classCardHoverInfoBottomItemName}>
							<div
								className={styles.classCardHoverInfoBottomItemNameItem}
								onClick={() => {
									const url = `/system/center/detail/groupDetail?id=${executor && executor.id}`
									history.push(url)
								}}
							>
								{executor && executor.name}
							</div>
						</div>
					</div>
					{/* 孩子 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconKidPng} />
						<div className={styles.classCardHoverInfoBottomItemName}>
							{students && students.map((item, index) => {
								return (
									<div
										className={styles.classCardHoverInfoBottomItemNameItem}
										onClick={() => {
											const url = `/system/center/detail/subjectDetail?id=${item && item.id}`
											dispatch({
												type: 'classCard/changeRoute',
												payload: {
													url
												}
											})
										}}
									>
										{item && item.name || ''}
									</div>
								)
							})}
						</div>
					</div>
					{/* 中心 */}
					{
						type === "oneself" &&
						<div className={styles.classCardHoverInfoBottomItem}>
							<img src={iconCenterPng} />
							<div className={styles.classCardHoverInfoBottomItemContent}>
								{tenantInfo && tenantInfo.displayName}
							</div>
						</div>
					}
					{/* 课程时间区间 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconTime2Png} />
						<div className={styles.classCardHoverInfoBottomItemContent}>
							{moment(startTime).format("MM/DD/YYYY")}({`${getWeekday(moment(startTime).day(), intl)}`})  {moment(backendStartTime || startTime).format("HH:mm")}-{moment(startTime).isSame(moment(endTime)) ? moment(moment(startTime).add(1, 'h')).format("HH:mm") : moment(moment(endTime)).format("HH:mm")}
						</div>
					</div>
					{/* 显示是否重复 */}
					{
						calendarCount > 0 &&
						<div className={styles.classCardHoverInfoBottomItem}>
							<img src={iconRepetitionPng} />
							<div className={styles.classCardHoverInfoBottomItemContent}>
								{getRepeatDescription(periodIntervalUnit, periodIntervalNumber, startTime, endTime, intl)}
							</div>
						</div>
					}
					{/* 显示线上线下课程 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconCoursePng} />
						<div className={styles.classCardHoverInfoBottomItemContent}>
							{
								calendarCourseType === "Online" ?
									<React.Fragment>{myTag("Online", intl)}</React.Fragment> :
									(
										calendarCourseType === "Offline" ?
											<React.Fragment>{myTag("Offline", intl)}</React.Fragment> :
											<span></span>
									)
							}
							{
								isRealItem &&
								<span>{planTypeEnum(intl)[trainingType] || ""}  {intl.formatMessage({ id : 'whichSession' }, { num: trainingCourseNum })}</span>
							}
						</div>
					</div>
				</div>
			</div>
			<MagicFormModal {...modalProps} />
			{/* 删除重复课程弹框 */}
			<MagicFormModal {...deleteModalProps} />
			<Drawer
				title={<b style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'usedSessions' })}</b>}
				open={showDrawer}
				width={720}
				onClose={() => dispatch({ type: 'classCard/updateState', payload: { showDrawer: false } })}
			>
				<div style={{ marginBottom: "10px", fontWeight: "600", fontSize: "16px" }}>{intl.formatMessage({ id: 'totalUsedSessions' })} {totalCourseNum}</div>
				<MagicTable {...TableProps} />
			</Drawer>
		</div>
	)
}

export default connect(({ classCard, loading, user }) => ({ classCard, loading, currentUser: user.currentUser, }))(ClassCardDetail)