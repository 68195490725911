import getRequest from 'utils/request';

const PostUserInfoApi = '/api/business/subject/getSubjectInfo'
const GetCarDataApi = '/api/rocketAdmin/subjectQuestionnaireStatistic/cars2';
const GetSnapDataApi = '/api/rocketAdmin/subjectQuestionnaireStatistic/snapIv';
const GetFasDataApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/subjectMfaAnalysis';
const GetTrainingInfoApi =
  '/api/rocketAdmin/userOfflinePlan/trainingInfo/{userOfflinePlanId}';
const GetHfsReportChildDevelopmentApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportChildDevelopment/{userOfflinePlanId}';
const GetHfsReportStrengthBasedInterviewApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportStrengthBasedInterview/{userOfflinePlanId}';
const GetHfsReportStrengthBasedInterviewFollowUpApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportStrengthBasedInterviewFollowUp/{userOfflinePlanId}';
const GetHfsVCatStatisticalApi =
  '/api/vrclassroom/DataAnalysis/Test/HfsVCatStatistical';
const GetHfsReportAnalysisApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportAnalysis/{userOfflinePlanId}';
const GetHfsReportCars2Api =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportCars2/{userOfflinePlanId}';
const GetHfsReportSnapIvApi =
  '/api/rocketAdmin/subjectQuestionnaireStatistic/hfsReportSnapIv/{userOfflinePlanId}';
const GetHfsReportCategoryAnalysisApi =
  '/api/rocketAdmin/userOfflinePlan/hfsReportCategoryAnalysis/{userOfflinePlanId}';
const GetHfsReportOverAllFeedbackApi =
  '/api/rocketAdmin/userOfflinePlan/hfsReportOverAllFeedback/{userOfflinePlanId}';

export async function PostUserInfo(data) {
  return getRequest()(PostUserInfoApi, {
    method: 'POST',
    data,
  });
}

export async function GetHfsReportCategoryAnalysis(data) {
  return getRequest()(
    GetHfsReportCategoryAnalysisApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportOverAllFeedback(data) {
  return getRequest()(
    GetHfsReportOverAllFeedbackApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetCarData(data) {
  return getRequest()(GetCarDataApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetSnapData(data) {
  return getRequest()(GetSnapDataApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetFasData(data) {
  return getRequest()(GetFasDataApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetTrainingInfo(data) {
  return getRequest()(
    GetTrainingInfoApi.replace('{userOfflinePlanId}', data.userOfflinePlanId),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportChildDevelopment(data) {
  return getRequest()(
    GetHfsReportChildDevelopmentApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportStrengthBasedInterview(data) {
  return getRequest()(
    GetHfsReportStrengthBasedInterviewApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsVCatStatistical(data) {
  return getRequest()(GetHfsVCatStatisticalApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetHfsReportStrengthBasedInterviewFollowUp(data) {
  return getRequest()(
    GetHfsReportStrengthBasedInterviewFollowUpApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportAnalysis(data) {
  return getRequest()(
    GetHfsReportAnalysisApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportCars2(data) {
  return getRequest()(
    GetHfsReportCars2Api.replace('{userOfflinePlanId}', data.userOfflinePlanId),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetHfsReportSnapIv(data) {
  return getRequest()(
    GetHfsReportSnapIvApi.replace(
      '{userOfflinePlanId}',
      data.userOfflinePlanId,
    ),
    {
      method: 'GET',
      params: data,
    },
  );
}
