import React from 'react';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import { useIntl } from 'umi';

const StrengthFirst = ({
  userInfo,
  strengthSurveyData = [],
  getCurrentPageNum,
}) => {
  const intl = useIntl();

  const { avatarContent } = userInfo || {};

  const headerContent = {
    pageNum: getCurrentPageNum(3),
    pageTitle: intl.formatMessage({ id: 'StrengthBased Interview' }),
    headerLogo: '',
  };

  const renderData = strengthSurveyData.slice(0, 2);

  return (
    <div id="StrengthBasedInterview" className={styles.page_div}>
      <div className={styles.strengthPage}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl
              .formatMessage({ id: 'StrengthBased Interview' })
              .toUpperCase()}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'Strength Based Des' },
              { name: avatarContent },
            )}
          </div>
          <p />

          {/* <TagCom
            tagTitle={'Interview Summary'}
            tagStyle={{ color: '#2B5B5D' }}
          />

          <div className={styles.InterviewContent}>InterviewContent</div>

          <hr /> */}

          <div className={styles.advantageInterviewsContent}>
            {renderData &&
              renderData.length > 0 &&
              renderData.map((item) => {
                const { title: pageTitle, elements = [], name } = item || {};

                return (
                  <div key={name}>
                    <div className={styles.advantageInterviewsTitleContent}>
                      <TagCom
                        tagTitle={pageTitle}
                        tagStyle={{ color: '#2B5B5D' }}
                      />
                    </div>

                    <div className={styles.advantageInterviewsMainContent}>
                      {elements.map((element) => {
                        const { name, title, value } = element || {};

                        return (
                          <div
                            key={name}
                            className={styles.advantageInterviewsItem}
                          >
                            <div
                              className={styles.advantageInterviewsItemTitle}
                            >
                              {title}
                            </div>
                            <div
                              className={styles.advantageInterviewsItemValue}
                            >
                              {value}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StrengthFirst);
