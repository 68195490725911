import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';

import cogleapLogo from 'assets/cogleap3.png';

const Contents = ({
  hiddenPageData,
  hfsReportCategoryAnalysis,
  startPage,
  endPage,
  getCurrentPageNum,
}) => {
  const intl = useIntl();

  const [
    isShowChildHistoryPage,
    isShowStrengthPage,
    isShowStrengthFollowUpPage,
    isShowCarsPage,
    isShowSnapPage,
  ] = hiddenPageData || [];

  const strengthBasedInterviewStartPage = getCurrentPageNum(
    3,
    hiddenPageData.slice(0, 1),
  );

  const followUpPage = getCurrentPageNum(3, hiddenPageData.slice(0, 2));

  const assessmentStatisticsPage = getCurrentPageNum(
    3,
    hiddenPageData.slice(0, 3),
  );

  const musicListeningPage = getCurrentPageNum(6, hiddenPageData);

  const jumpToPage = (pageId) => {
    document.getElementById(pageId).scrollIntoView({ behavior: 'smooth' });
  };

  const showList = [
    {
      title: intl.formatMessage({ id: 'HOME WELCOME LETTER' }),
      id: 'HomeWelcomeLetter',
      pageNum: 1,
    },
    {
      title: intl.formatMessage({ id: 'PERSONAL INFORMATION' }),
      id: 'PERSONALInformation',
      pageNum: 2,
    },
    {
      title: intl.formatMessage({ id: 'TRAINING INFORMATION' }),
      id: 'TRAININGInformation',
      pageNum: 2,
    },
    isShowChildHistoryPage && {
      title: intl.formatMessage({ id: 'Child Development History' }),
      id: 'ChildDevelopmentHistory',
      pageNum: 3,
    },
    isShowStrengthPage && {
      title: intl.formatMessage({ id: 'StrengthBased Interview' }),
      id: 'StrengthBasedInterview',
      pageNum: `${strengthBasedInterviewStartPage}-${
        strengthBasedInterviewStartPage + 2
      }`,
    },
    isShowStrengthFollowUpPage && {
      title: intl.formatMessage({ id: 'StrengthBased Interview FollowUp' }),
      id: 'StrengthBasedInterviewFollowUp',
      pageNum: followUpPage,
    },
    {
      title: intl.formatMessage({ id: 'Assessment Statistics' }),
      id: 'AssessmentStatistics',
      pageNum: `${assessmentStatisticsPage}-${musicListeningPage - 1}`,
    },
    {
      title: intl.formatMessage({ id: 'Music Listening Therapy' }),
      id: 'MusicListeningTherapy',
      pageNum: musicListeningPage,
    },
    {
      title: intl.formatMessage({ id: 'Skill Exercise Therapy' }),
      id: 'SkillBasedExercise',
      pageNum: startPage - 1,
    },
    {
      title: intl.formatMessage({ id: 'SkillOverall' }),
      id: 'SkillBasedExerciseOverall',
      pageNum: startPage,
    },
    ...hfsReportCategoryAnalysis.map((item, index) => {
      const { trainingCategory } = item || {};
      const { displayName, name } = trainingCategory || {};
      const _startPage = startPage + index * 2;
      return {
        title: intl.formatMessage({ id: 'SkillDisplayName' }, { displayName }),
        id: 'SkillBasedExerciseCategoryAnalysis' + name,
        pageNum: `${_startPage + 1}-${_startPage + 2}`,
      };
    }),
    {
      title: intl.formatMessage({ id: 'TransitionsMental' }),
      id: 'TransitionMental',
      pageNum: `${endPage + 1}-${endPage + 4}`,
    },
  ].filter((item) => item);

  return (
    <div id="Contents" className={styles.page_div}>
      <div className={styles.contentsPage}>
        <div className={styles.centerContent}>
          <div className={styles.reportTitle}>
            {intl.formatMessage({ id: 'Contents' })}
          </div>

          <div className={styles.contentList}>
            {showList.map((item) => {
              const { id, pageNum, title } = item || {};
              return (
                <div
                  className={styles.contentItem}
                  onClick={() => jumpToPage(id)}
                  key={id}
                >
                  <div className={styles.contentItemTitle}>{title}</div>

                  <hr />
                  <div className={styles.contentItemPageNum}>{pageNum}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.logo}>{<img src={cogleapLogo} />}</div>
      </div>
    </div>
  );
};

export default React.memo(Contents);
