import React from 'react';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';

import { useIntl } from 'umi';

import moment from 'moment';

const StrengthFollowUp = ({
  userInfo,
  strengthFollowUpAnswerList,
  getCurrentPageNum,
}) => {
  const intl = useIntl();

  const { avatarContent } = userInfo || {};

  let showKeys = [];
  if (strengthFollowUpAnswerList && strengthFollowUpAnswerList.length > 0) {
    const { parseAnswer = [] } = strengthFollowUpAnswerList[0];
    showKeys = parseAnswer
      .map((item) => {
        const { elements = [], title: pageTitle } = item || {};
        return elements.map((item) => {
          const { name, title } = item || {};
          const _title = title.match(/^[^：:]+/)[0];
          return {
            key: name,
            title: elements.length > 1 ? _title : pageTitle,
          };
        });
      })
      .flat();
  }

  const headerContent = {
    pageNum: getCurrentPageNum(3),
    pageTitle: intl.formatMessage({ id: 'StrengthBased Interview FollowUp' }),
    headerLogo: '',
  };

  return (
    <div id="StrengthBasedInterviewFollowUp" className={styles.page_div}>
      <div className={styles.strengthPage}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl
              .formatMessage({ id: 'StrengthBased Interview FollowUp' })
              .toUpperCase()}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'Strength Based FollowUp Des' },
              { name: avatarContent },
            )}
          </div>

          {/* <TagCom
            tagTitle={'Interview Summary'}
            tagStyle={{ color: '#2B5B5D' }}
          />

          <div className={styles.InterviewContent}>InterviewContent</div>
          <hr /> */}
          <p />

          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '180px' }}>
                  {intl.formatMessage({ id: 'question' })}
                </th>
                {strengthFollowUpAnswerList.map((item) => {
                  const { lastModificationTime, creationTime, id } = item || {};
                  const finishTime = lastModificationTime || creationTime;
                  return (
                    <th style={{ textAlign: 'left' }} key={id}>
                      {moment(finishTime).format('MM/DD/YYYY HH:mm')}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {showKeys &&
                showKeys.length > 0 &&
                showKeys.map(({ key, title }) => {
                  return (
                    <tr key={key}>
                      <td>{title}</td>
                      {strengthFollowUpAnswerList.map((item) => {
                        const { parseAnswer = [] } = item || {};
                        const totalElement = parseAnswer
                          .map((answer) => answer.elements)
                          .flat();

                        const { value = '' } =
                          totalElement.find((answer) => answer.name === key) ||
                          {};
                        return <td key={`td${key}`}>{value}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StrengthFollowUp);
