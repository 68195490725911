import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import FasReportTable from 'cognitiveleap-core-us/components/ArchivesV2/UserDataPanel/components/FasReportTable';

const FundamentalTables = ({ fasReportData, getCurrentPageNum }) => {
  const intl = useIntl();

  const { questionnaireData = [] } = fasReportData || {};

  const headerContent = {
    pageNum: getCurrentPageNum(4),
    pageTitle: intl.formatMessage({ id: 'Assessment Statistics' }),
    headerLogo: '',
  };

  const reportProps = {
    dataSource:
      questionnaireData.length > 0 ? questionnaireData?.slice(0, 3) : [],
    from: 'report',
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.fundamentalTables}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom
            tagTitle={intl.formatMessage({
              id: 'Fundamental Abilities Survey Charts',
            })}
          />
          <div className={styles.titleDes}>
            {intl.formatMessage({
              id: 'Fundamental Abilities Survey ChartsDes',
            })}
          </div>

          <div style={{ marginTop: '20px' }}>
            <FasReportTable {...reportProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FundamentalTables);
