import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/lib/echarts';

import styles from './index.less';

import moment from 'moment';

export function connectOneweek(startTime, endTime) {
  let dateArr = [moment(startTime).format('YYYY-MM-DD')];
  for (let i = 0; moment(startTime).isBefore(moment(endTime)); i++) {
    startTime = moment(startTime).add(1, 'days');
    dateArr.push(moment(startTime).format('YYYY-MM-DD'));
  }
  return dateArr;
}

const TrainingCalendar = ({ calendarData }) => {
  const iLsEcharts = useRef();
  const [scatterData, setScatterData] = useState([]);

  const { month, year, doProportions = [] } = calendarData || {};

  // 月份从 0 开始
  const currentDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month');
  const currentMonth = currentDate.format('YYYY-MM');

  const getCurrentMonth = () => {
    const startDay = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
    const endDay = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
    const monthDay = connectOneweek(startDay, endDay);
    return {
      startDay,
      endDay,
      monthDay,
    };
  };

  const getData = () => {
    let result = {};

    const currentMonthInfo = getCurrentMonth();

    doProportions.length > 0 &&
      doProportions.forEach((item) => {
        const day = moment(item.day).utcOffset(0).format('YYYY-MM-DD');
        const allCount = item.needDoCount;
        // 训练师反馈比例
        const finishedData = item.dayFinished / allCount;
        // 打卡提交项目比例
        const checkData = item.guardianFinished / allCount;
        // 未完成项目比例
        const notDoData = item.dayNotFinished / allCount;
        !result[day] &&
          (result[day] = {
            finishedData,
            checkData,
            notDoData,
            courseCount: item.courseCount,
            isOnlineOrOfflineData: item.isOnApp,
          });
      });

    return currentMonthInfo.monthDay.map((item) => [
      item, // 日期
      item == moment().format('YYYY-MM-DD') ? 1 : 0, // 判断是否是今日
      result[item] ? result[item].finishedData : 0, // 训练师反馈比例
      result[item] ? result[item].checkData : 0, // 打卡提交项目比例
      result[item] ? result[item].notDoData : 0, // 未完成项目比例
      result[item] ? result[item].courseCount : 0,
      result[item] ? true : false, // 是否已结束课时
      result[item] ? result[item].isOnlineOrOfflineData : [], // 显示线上或者线下课程
    ]);
  };

  useEffect(() => {
    const _scatterData = getData();
    setScatterData(_scatterData);
  }, []);

  useEffect(() => {
    if (iLsEcharts.current && iLsEcharts.current.getEchartsInstance()) {
      renderEcharts();
    }
  }, [iLsEcharts.current]);

  const getInitSeries = (data) => {
    return [
      {
        id: 'label',
        type: 'scatter',
        silent: true,
        coordinateSystem: 'calendar',
        symbolSize: 1,
        calendarIndex: 0,
        legendHoverLink: false,
        data,
        label: {
          show: true,
          formatter: function (params) {
            return moment(params.value[0]).format('DD');
          },
          offset: [-8, -10],
          color: '#3C4858',
          fontSize: 12,
        },
      },
      {
        name: '显示当天颜色',
        silent: true,
        type: 'heatmap',
        data,
        coordinateSystem: 'calendar',
        itemStyle: {
          color: (params) => {
            return params.value[0] == moment().format('YYYY-MM-DD')
              ? '#E6F4F4'
              : '#FFF';
          },
        },
      },
    ];
  };

  const initOption = {
    color: ['#93D9DC', '#55B3B7', '#F0F0F0', '#E6F4F4', '#FF80C0'],
    tooltip: {
      show: false,
    },
    title: {
      text: currentDate.format('MM-YYYY'),
      textStyle: {
        fontSize: 14,
        color: '#A7A7A7',
        fontWeight: 'normal',
      },
      padding: [0, 0, 20, 0],
    },
    visualMap: {
      show: false,
      inRange: {},
    },
    calendar: [
      {
        width: '100%',
        left: 'center',
        orient: 'vertical',
        cellSize: ['auto', '35'],
        yearLabel: {
          show: false,
        },
        dayLabel: {
          nameMap: 'cn',
          color: '#A8AEB8',
          fontSize: 14,
        },
        monthLabel: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#eee',
            width: 0.5,
          },
        },
        itemStyle: {
          borderWidth: 0.5,
          color: '#eee',
        },
        range: [currentDate.format('YYYY-MM')],
      },
    ],
    series: getInitSeries(scatterData),
  };

  const renderEcharts = () => {
    const positionSeries = getInitSeries(scatterData);
    iLsEcharts.current
      .getEchartsInstance()
      .setOption({ ...initOption, series: positionSeries });

    const dataSeries = echarts.util.map(scatterData, (item, index) => {
      const center = iLsEcharts.current
        .getEchartsInstance()
        .convertToPixel('calendar', item);

      const series = [
        {
          id: index + 'pie',
          type: 'pie',
          center: [center[0] - 7, center[1]],
          silent: true,
          top: 7,
          label: {
            formatter: '',
            position: 'inside',
          },
          emphasis: {
            scale: false
          },
          radius: item[6] ? 7.5 : 0,
          data: [
            { name: '完成项目比例-训练师端', value: item[2] },
            { name: '完成项目比例-家长端', value: item[3] },
            {
              name: '未完成项目比例-训练师端',
              value: item[2] + item[3] ? item[4] : 1,
            },
            { name: '今天', value: 0 },
            { name: '线上课', value: 0 },
            { name: '线下课', value: 0 },
          ],
        },
      ];
      // 显示线上线下课程
      const showOnlineOrOffline = item[7].slice(0, 4).map((item, index) => {
        return {
          type: 'pie',
          silent: true,
          label: {
            formatter: '',
            position: 'inside',
          },
          center: [center[0] + 10, center[1] + 12 - index * 7.5],
          radius: 2.5,
          emphasis: {
            scale: false
          },
          itemStyle: {
            color: item.isApp ? '#FFCC01' : '#FF80C0',
          },
          data: [{ name: '', value: 0 }],
        };
      });

      const result = [...series].concat(showOnlineOrOffline);
      return result;
    });

    iLsEcharts.current.getEchartsInstance().setOption(
      {
        ...initOption,
        series: positionSeries.concat(...dataSeries),
      },
      true,
    );
  };

  return (
    <div>
      <ReactEcharts
        ref={iLsEcharts}
        option={initOption}
        opts={{ renderer: 'svg' }}
        style={{ height: '280px', width: '248px' }}
      />
    </div>
  );
};

export default React.memo(TrainingCalendar);
