import React from 'react';
import { useIntl } from 'umi';
import ReactEcharts from 'echarts-for-react';
import styles from './index.less';

const AnalysisChart = ({
  analysisValues = [],
  linearValues = [],
  feedbackProperty = {},
  showKey,
}) => {
  const intl = useIntl();

  let maxDifficulty = 1;

  const { displayName = '' } = feedbackProperty || {};

  const [lineNameOne, lineNameTwo] = displayName.split('- ') || [];

  const lineName = lineNameTwo || lineNameOne;

  const xAxisData = [],
    seriesData = [],
    decreaseMarkPoints = [],
    increaseMarkPoints = [];

  const isMentalData =
    showKey === 'emotionalState' ||
    showKey === 'focusAttention' ||
    showKey === 'socialInteraction' ||
    showKey === 'selfRegulation' ||
    showKey === 'motivationLevel';

  // 分值为4份，其他为3份
  const isEmotional = showKey === 'enjoymentLevel';
  const splitValue = isEmotional ? 4 : 3;

  if (!isMentalData) {
    maxDifficulty = Math.max(
      ...analysisValues.filter((item) => item).map((item) => item.difficulty),
    );

    if (maxDifficulty > 10) {
      maxDifficulty = maxDifficulty + (maxDifficulty % 2);
    }
  }

  const getMiddleTicks = () => {
    const middleTicks = [];
    const _splitValue = maxDifficulty > 10 ? splitValue * 2 : splitValue;

    const max = maxDifficulty * _splitValue;

    for (let k = 1; k <= max; k++) {
      const middlePoint = (k - 1) * _splitValue + _splitValue / 2;
      if (middlePoint <= max) {
        // 不超过最大值
        middleTicks.push(middlePoint);
      }
    }
    return middleTicks;
  };

  const chartTitle = intl.formatMessage(
    {
      id: isMentalData
        ? 'Changes with Training'
        : 'Score for Activity Difficulty',
    },
    { type: lineName },
  );

  const { yName, showMakePoint, chartHeight, chartWidth } = {
    performance1: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    performance2: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    adherenceToStandards: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    confidenceLevel: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    activityCompletion: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    enjoymentLevel: {
      yName: 'Difficulty',
      showMakePoint: true,
      chartHeight: '268px',
      chartWidth: '385px',
    },
    // 另一种图例
    emotionalState: {
      yName: 'Score',
      showMakePoint: true,
      chartHeight: '280px',
      chartWidth: '585px',
    },
    focusAttention: {
      yName: 'Score',
      showMakePoint: true,
      chartHeight: '280px',
      chartWidth: '585px',
    },
    socialInteraction: {
      yName: 'Score',
      showMakePoint: true,
      chartHeight: '280px',
      chartWidth: '585px',
    },
    selfRegulation: {
      yName: 'Score',
      showMakePoint: true,
      chartHeight: '280px',
      chartWidth: '585px',
    },
    motivationLevel: {
      yName: 'Score',
      showMakePoint: true,
      chartHeight: '280px',
      chartWidth: '585px',
    },
  }[showKey];

  analysisValues.forEach((item) => {
    const { difficultyDecrease, difficultyIncrease, num, value, difficulty } =
      item || {};

    xAxisData.push(num);
    if (isMentalData) {
      seriesData.push(value);
    } else {
      if (
        value == null ||
        value == undefined ||
        difficulty == null ||
        difficulty == undefined
      ) {
        seriesData.push(value);
      } else {
        const data = (difficulty - 1) * splitValue + value;
        seriesData.push(data);

        if (difficultyDecrease) {
          // index are 0 based
          decreaseMarkPoints.push([num - 1, data]);
        }
        if (difficultyIncrease) {
          increaseMarkPoints.push([num - 1, data]);
        }
      }
    }
  });

  const activeData = seriesData.filter((item) => item);

  const isEmpty = activeData.length === 0;

  const option = {
    color: ['#2b5b5d'],
    legend: {
      show: showMakePoint,
      data: isMentalData
        ? [
            {
              name: lineName,
              icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAGCAIAAABWyclEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAABgAAAAA95VxOAAAAGUlEQVQYGWPUjo5loAZgooYhIDNGDSIckgCOcwDvMSZBJwAAAABJRU5ErkJggg==',
            },
            {
              name: intl.formatMessage(
                { id: 'Linear Trendline' },
                { displayName: lineName },
              ),
              icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAADCAYAAADlYJ2lAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAAAwAAAAAYy+h+AAAAS0lEQVQYGWMMWL9egIWFw4ABCfz58+PChsDAD0hCcOZAqWcBOZKRgWE/3CVABlDMEUgdQBaDsQdKPQso9KAOg7mFASQG56AxBko9AAaWRJAc90gnAAAAAElFTkSuQmCC',
            },
          ]
        : [
            {
              name: lineName,
              icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAGCAIAAABWyclEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAABgAAAAA95VxOAAAAGUlEQVQYGWPUjo5loAZgooYhIDNGDSIckgCOcwDvMSZBJwAAAABJRU5ErkJggg==',
            },
            {
              name: intl.formatMessage({ id: 'Difficulty Increase' }),
              icon: 'triangle',
              itemStyle: {
                color: '#75BD43',
              },
            },
            {
              name: intl.formatMessage({ id: 'Difficulty Decrease' }),
              icon: 'diamond',
              itemStyle: {
                color: '#EE822F',
              },
            },
          ],
      bottom: '1%',
      selectedMode: false,
      itemWidth: isMentalData ? 24 : 13,
      itemHeight: 12,
      textStyle: {
        fontSize: isMentalData ? 12 : 9,
      },
    },
    grid: isMentalData
      ? {}
      : {
          top: '22%',
          left: '3%',
          right: '11%',
          bottom: '10%',
          containLabel: true,
        },
    title: {
      text: chartTitle,
      left: 'center',
      textStyle: {
        fontSize: 12,
      },
      top: '2%',
    },
    xAxis: isMentalData
      ? {
          type: 'category',
          data: xAxisData,
          name: intl.formatMessage({ id: 'session' }),
          nameLocation: 'middle',
          nameTextStyle: {
            padding: [12, 0, 0, 0],
          },
        }
      : {
          type: 'category',
          data: xAxisData,
          name: intl.formatMessage({ id: 'session' }),
          nameGap: 5,
          nameLocation: 'end',
          nameTextStyle: {
            padding: [2, 0, 0, 0], // 上方增加 20px 间距
            verticalAlign: 'top', // 确保名称底部对齐
          },
        },
    yAxis: isMentalData
      ? {
          type: 'value',
          name: intl.formatMessage({ id: yName }),
        }
      : [
          {
            type: 'value',
            name: intl.formatMessage({ id: yName }),
            interval: maxDifficulty > 10 ? 2 : 1,
            min: 0,
            max: maxDifficulty,
            axisLabel: {
              verticalAlign: 'top',
              padding: [5, 0, 0, 0],
              formatter: (value) => {
                if (value == 0) {
                  return '';
                }
                return value;
              },
            },
          },
          {
            type: 'value',
            name: intl.formatMessage({ id: 'Score' }),
            min: 0,
            max: maxDifficulty * splitValue,
            interval: 0.5,
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: (value) => {
                const data = getMiddleTicks();

                if (data.includes(value)) {
                  return `(1~${splitValue})`;
                }
              },
            },
          },
        ],
    series: [
      {
        data: isMentalData ? seriesData : [],
        type: 'line',
        name: lineName,
        symbol: activeData.length > 1 ? 'none' : 'circle',
        connectNulls: true,
        lineStyle: {
          width: 3,
        },
      },
      !isMentalData && {
        name: intl.formatMessage({ id: 'Score' }),
        type: 'line',
        data: seriesData,
        symbol: seriesData.length > 1 ? 'none' : 'circle',
        connectNulls: true,
        lineStyle: {
          width: 3,
        },
        yAxisIndex: 1,
        markPoint: {
          data: showMakePoint
            ? [
                // 为特定数据点设置不同的标记
                ...increaseMarkPoints.map(([x, y]) => ({
                  coord: [x, y],
                  symbol: 'triangle',
                  symbolSize: 13,
                  itemStyle: { color: '#91ca69' },
                })),
                ...decreaseMarkPoints.map(([x, y]) => ({
                  coord: [x, y],
                  symbol: 'diamond',
                  symbolSize: 13,
                  itemStyle: { color: '#f19b59' },
                })),
              ]
            : [],
        },
      },
      linearValues.length > 0 && {
        data: xAxisData.map((item) => {
          const { y } = linearValues.find((v) => v.x === item) || {};
          return y;
        }),
        type: 'line',
        name: intl.formatMessage(
          { id: 'Linear Trendline' },
          { displayName: lineName },
        ),
        symbol: 'none',
        connectNulls: true,
        lineStyle: {
          width: 1,
          color: '#55b3b7',
          type: 'dashed',
        },
      },
      {
        name: intl.formatMessage({ id: 'Difficulty Increase' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'Difficulty Decrease' }),
        type: 'scatter',
        data: [],
      },
    ],
  };

  return (
    <div className={styles.brainLineChart}>
      <ReactEcharts
        option={option}
        notMerge={true}
        opts={{ renderer: 'svg' }}
        style={{ height: chartHeight, width: chartWidth, margin: 'auto' }}
      />
      {isEmpty && (
        <div className={styles.brainLineChartEmpty}>
          {intl.formatMessage({ id: 'emptyData' })}
        </div>
      )}
    </div>
  );
};

export default React.memo(AnalysisChart);
