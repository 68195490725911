import React from 'react';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import GraphChart from './charts/GraphChart';

import { useIntl, getLocale } from 'umi';

const FollowingBehaviors = ({
  hfsReportOverAllFeedback,
  userInfo,
  pageNum,
}) => {
  const intl = useIntl();

  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const showKey = 'behavioralActivity';
  const showKey2 = 'communicationStyle';
  const { behaviorFeedbacks = [] } = hfsReportOverAllFeedback || {};

  const { avatarContent } = userInfo || {};

  const behavioralChartData =
    behaviorFeedbacks.length > 0
      ? behaviorFeedbacks.find((item) => item.feedbackProperty.name === showKey)
      : {};

  const communicationChartData =
    behaviorFeedbacks.length > 0
      ? behaviorFeedbacks.find(
          (item) => item.feedbackProperty.name === showKey2,
        )
      : {};

  const { feedbackProperty, valueTimes = [] } = behavioralChartData || {};
  const { extraProperties = {} } = feedbackProperty || {};
  const { options = [] } = extraProperties[language] ?? extraProperties;

  const { feedbackProperty: feedbackProperty2, valueTimes: valueTimes2 = [] } =
    communicationChartData || {};
  const { extraProperties: extraProperties2 = {} } = feedbackProperty2 || {};
  const { options: options2 = [] } =
    extraProperties2[language] ?? extraProperties2;

  const activeOptions = options?.filter(
    (item) => item.value !== 'customizeOthers',
  );

  const activeOptions2 = options2?.filter(
    (item) => item.value !== 'customizeOthers',
  );

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({ id: 'TransitionsMental' }),
    headerLogo: '',
  };

  const behavioralChartProps = {
    showKey,
    chartData: behavioralChartData,
  };

  const communicationChartProps = {
    showKey: showKey2,
    chartData: communicationChartData,
  };

  const colorList = [
    '#c81d31',
    '#ee822f',
    '#f2ba02',
    '#75bd42',
    '#30c0b4',
    '#249087',
    '#254380',
    '#4d1f70',
  ];

  return (
    <div className={styles.page_div}>
      <div className={styles.transitionBehaviors}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.titleDes} style={{ marginTop: '10px' }}>
            {intl.formatMessage(
              { id: 'two charts frequencyDes' },
              { name: avatarContent },
            )}
          </div>

          <GraphChart {...behavioralChartProps} />
          <div style={{ marginTop: '5px' }}>
            <GraphChart {...communicationChartProps} />
          </div>

          <table style={{ marginTop: '15px', whiteSpace: 'pre-line' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', maxWidth: '100px' }}></th>
                <th style={{ textAlign: 'center', maxWidth: '60px' }}>
                  {intl.formatMessage({ id: 'Legend' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '100px' }}>
                  {intl.formatMessage({ id: 'Transition Behaviors' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '210px' }}>
                  {intl.formatMessage({ id: 'Description' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '50px' }}>
                  {intl.formatMessage({ id: 'Times' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {activeOptions &&
                activeOptions.length > 0 &&
                activeOptions.map(({ text, description, value }, index) => {
                  const { times } =
                    valueTimes.find((item) => item.value == value) || {};

                  return (
                    <tr key={value}>
                      {index == 0 && (
                        <th
                          rowSpan={activeOptions.length}
                          style={{ textAlign: 'center' }}
                        >
                          <div style={{ maxWidth: '100px' }}>
                            {intl.formatMessage({ id: 'Behavioral Activity' })}
                          </div>
                        </th>
                      )}
                      <td style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: colorList[index],
                            borderRadius: '50%',
                            margin: '0 auto',
                          }}
                        ></div>
                      </td>
                      <td>{text}</td>
                      <td>{description}</td>
                      <td style={{ textAlign: 'center' }}>{times}</td>
                    </tr>
                  );
                })}
              {activeOptions2 &&
                activeOptions2.length > 0 &&
                activeOptions2.map(({ text, description, value }, index) => {
                  const { times } =
                    valueTimes2.find((item) => item.value == value) || {};

                  return (
                    <tr key={value}>
                      {index == 0 && (
                        <th
                          rowSpan={activeOptions2.length}
                          style={{ textAlign: 'center' }}
                        >
                          <div style={{ maxWidth: '100px' }}>
                            {intl.formatMessage({ id: 'Communication Style' })}
                          </div>
                        </th>
                      )}
                      <td style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: colorList[index],
                            borderRadius: '50%',
                            margin: '0 auto',
                          }}
                        ></div>
                      </td>
                      <td>{text}</td>
                      <td>{description}</td>
                      <td style={{ textAlign: 'center' }}>{times}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FollowingBehaviors);
