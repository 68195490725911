import React from 'react';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import GraphChart from './charts/GraphChart';

import { useIntl, getLocale } from 'umi';

const TransitionBehaviors = ({
  hfsReportOverAllFeedback,
  userInfo,
  pageNum,
}) => {
  const intl = useIntl();

  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const showKey = 'transitionalBehavior';
  const { behaviorFeedbacks = [] } = hfsReportOverAllFeedback || {};
  const { avatarContent } = userInfo || {};

  const chartData =
    behaviorFeedbacks.length > 0
      ? behaviorFeedbacks.find((item) => item.feedbackProperty.name === showKey)
      : {};

  const { feedbackProperty, valueTimes = [] } = chartData || {};
  const { extraProperties = {} } = feedbackProperty || {};
  const { options = [] } = extraProperties[language] ?? extraProperties;

  const activeOptions = options?.filter(
    (item) => item.value !== 'customizeOthers',
  );

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({
      id: 'TransitionsMental',
    }),
    headerLogo: '',
  };

  const chartProps = {
    showKey,
    chartData,
  };

  const colorList = [
    '#c81d31',
    '#ee822f',
    '#f2ba02',
    '#75bd42',
    '#30c0b4',
    '#249087',
    '#254380',
    '#4d1f70',
  ];

  const xAxisData = behaviorFeedbacks[0]?.values?.map((item) => item.num) || [];
  const sessionsNum = xAxisData[xAxisData.length - 1];

  return (
    <div id="TransitionMental" className={styles.page_div}>
      <div className={styles.transitionBehaviors}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl.formatMessage({ id: 'TransitionsMental' })}
          </div>
          <TagCom
            tagTitle={intl.formatMessage({
              id: 'Graph by Transition Behaviors',
            })}
          />
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'transition behaviorsDes' },
              { name: avatarContent, sessions: sessionsNum },
            )}
          </div>

          <GraphChart {...chartProps} />

          <table style={{ marginTop: '40px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', maxWidth: '60px' }}>
                  {intl.formatMessage({ id: 'Legend' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '110px' }}>
                  {intl.formatMessage({ id: 'Transition Behaviors' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '180px' }}>
                  {intl.formatMessage({ id: 'Description' })}
                </th>
                <th style={{ textAlign: 'center', maxWidth: '50px' }}>
                  {intl.formatMessage({ id: 'Times' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {activeOptions &&
                activeOptions.length > 0 &&
                activeOptions.map(({ text, description, value }, index) => {
                  const { times } =
                    valueTimes.find((item) => item.value == value) || {};
                  return (
                    <tr key={value}>
                      <td style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: colorList[index],
                            borderRadius: '50%',
                            margin: '0 auto',
                          }}
                        ></div>
                      </td>
                      <td>{text}</td>
                      <td>{description}</td>
                      <td style={{ textAlign: 'center' }}>{times}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransitionBehaviors);
