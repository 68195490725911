import cogleapLogo from 'assets/cogleap3.png';
import styles from '../index.less';

const HeaderContent = ({ pageNum, pageTitle, headerLogo }) => {
  return (
    <div className={styles.headerContent}>
      <div className={styles.headerTitle}>
        P{pageNum} - {pageTitle}
      </div>
      <img src={headerLogo || cogleapLogo} alt="header logo" />
    </div>
  );
};

export default HeaderContent;
