import React from 'react';
import { Space } from 'antd';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';
import { getAdolescentRecommendProject } from 'cognitiveleap-core-us/report/utils';

import BrainLineChart from './charts/BrainLineChart';

import { useIntl, getLocale } from 'umi';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es-ES',
};

const MusicListeningTherapy = ({
  trainingInfo,
  musicProgressData,
  getCurrentPageNum,
}) => {
  const intl = useIntl();

  const language = LangOptions[getLocale()];

  const { musicModules = [] } = trainingInfo || {};

  const showMusicModules = musicModules
    .map((item) => item.toUpperCase())
    .join('+');

  const headerContent = {
    pageNum: getCurrentPageNum(6),
    pageTitle: intl.formatMessage({ id: 'Music Listening Therapy' }),
    headerLogo: '',
  };

  const getMusicTargetText = () => {
    let showId = '';
    switch (showMusicModules?.toLowerCase()) {
      case 'c+sm':
        showId = 'CSMDes';
        break;
      case 'sm':
        showId = 'SMDes';
        break;
      case 'ca':
        showId = 'CADes';
        break;
      case 'rap':
        showId = 'RAPDes';
        break;
      case 'opi+opii':
      case 'hcf1+hcf2':
        showId = 'HCFDes';
        break;
      default:
        showId = 'CSMDes';
        break;
    }

    return intl.formatMessage({ id: showId });
  };

  const brainLineChartProps = {
    dataSource: musicProgressData,
  };

  return (
    <div id="MusicListeningTherapy" className={styles.page_div}>
      <div className={styles.musicListeningTherapy}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl
              .formatMessage({ id: 'Music Listening Therapy' })
              .toUpperCase()}
          </div>
          <TagCom tagTitle={intl.formatMessage({ id: 'HFS Music' })} />

          <div className={styles.musicPlanContent}>
            <Space align="start" size={5}>
              <div className={styles.planTitle}>
                {intl.formatMessage({ id: 'Music Module' })}:{' '}
              </div>
              <div>
                {getAdolescentRecommendProject(showMusicModules, language, false)}
              </div>
            </Space>
            <Space align="start" size={5} style={{ marginTop: '4px' }}>
              <div className={styles.planTitle}>
                {intl.formatMessage({ id: 'Music Target' })}:{' '}
              </div>
              <div className={styles.planContentText}>
                {getMusicTargetText()}
              </div>
            </Space>
          </div>

          <BrainLineChart {...brainLineChartProps} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(MusicListeningTherapy);
