import React from 'react';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import { useIntl } from 'umi';

const WelcomeLetters = ({ userInfo }) => {
  const intl = useIntl();

  const { avatarContent } = userInfo || {};

  const headerContent = {
    pageNum: 1,
    pageTitle: intl.formatMessage({ id: 'HOME WELCOME LETTER' }),
    headerLogo: '',
  };

  return (
    <div id="HomeWelcomeLetter" className={styles.page_div}>
      <div className={styles.welcomeLetters}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl.formatMessage({ id: 'HOME WELCOME LETTER' })}
          </div>
          <div className={styles.letterContent}>
            {intl.formatMessage(
              { id: 'WelcomeLetterTip' },
              { name: avatarContent },
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WelcomeLetters);
