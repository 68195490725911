import { connect, history, useIntl } from 'umi'
import { useEffect } from 'react';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { Input, Row, Col, Modal, Button, Card } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
const { Search } = Input
const { confirm } = Modal;

const UserList = ({ dispatch, adminUserlist, loading }) => {
  const intl = useIntl();
  const isLoading = loading.effects['adminUserlist/loadPage'];
  const { list, pagination, searchField, modalType, content, modalVisible, currentTenant } = adminUserlist

  useEffect(() => {
    dispatch({ type: 'adminUserlist/changeTenant' })
  }, [])

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    nickName: {
      showText: intl.formatMessage({ id: 'nickName' }),
      showType: 'Text',
    },
    phone: {
      showText: intl.formatMessage({ id: 'phone' }),
      showType: 'Text',
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
    },
    appVersion:{
      showText: intl.formatMessage({ id: 'appVersion' }),
      showType: 'Text',
    },
    email: {
      showText: intl.formatMessage({ id: 'email' }),
      showType: 'Text',
    },
    emailConfirmed: {
      showText: intl.formatMessage({ id: 'emailConfirmed' }),
      showType: 'Bool',
    },
    phoneConfirmed: {
      showText: intl.formatMessage({ id: 'phoneConfirmed' }),
      showType: 'Bool',
    },
    vipStatus: {
      showText: intl.formatMessage({ id: 'vipStatus' }),
      showType: 'Text',
    },
    subjectCount: {
      showText: intl.formatMessage({ id: 'subjectCount' }),
      showType: 'Text',
    },
    // userStatus: {
    //   showText: '用户状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['已激活', '已冻结'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [{ text: '已激活', value: 'on' }, { text: '已冻结', value: 'off' }],
    //   },
    // },
    // vipStatus: {
    //   showText: '会员状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['非会员', '会员', '过期会员'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [
    //       { text: '非会员', value: 'None' },
    //       { text: '会员', value: 'Vip' },
    //       { text: '过期会员', value: 'ExpiredVip ' },
    //     ],
    //   },
    // },
  }

  const searchProps = {
    placeholder: intl.formatMessage({ id: 'UserList' }),
    onSearch(value) {
      dispatch({ type: 'adminUserlist/filterFetch', payload: { searchField: value } })
    },
    onChange(value) {
      dispatch({ type: 'adminUserlist/updateState', payload: { searchField: value.target.value } })
    },
    value: searchField,
  }

  const showList = list.map(item => {
    const { subjects } = item
    const subjectCount = subjects.length
    return {
      ...item,
      subjectCount,
    }
  })

  const tableProps = {
    listData: showList,
    listSupport,
    pagination,
    loading: isLoading,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'adminUserlist/changeTable', payload: data })
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'upgrade VIP' }),
        onClick(record) {
          confirm({
            title: intl.formatMessage({ id: 'confirm upgrade VIP' }),
            onOk() {
              dispatch({ type: 'adminUserlist/levelup', payload: record.id })
            },
          })
        },
      },
      {
        showText: '注销',
        itemRender: (record) => {
          return (
            <a onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleFilled />,
                title: (
                  <span style={{ color: 'red', fontWeight: 500 }}>
                    注销家长后，家长名下所有儿童也将被同步注销，无法再查看用户信息、训练数据等内容，请确认。
                  </span>
                ),
                okText: "确认",
                cancelText: "取消",
                cancelButtonProps: {
                  style: {
                    background: '#fff',
                    color: '#5a5a5a',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  },
                },
                okButtonProps: {
                  style: {
                    background: 'red',
                    color: '#fff',
                    borderRadius: '5px',
                    border: '1px solid red',
                  },
                },
                onOk: () => {
                  dispatch({ type: 'adminUserlist/deleteUser', payload: record })
                }
              })
            }}>
              注销
            </a>
          )
        }
      },
      {
        showText: intl.formatMessage({ id: 'details' }),
        onClick(record){
          history.push({
            pathname: '/system/manage/userDetail',
            query:{
              id: record.id
            }
          })
        }
      }
    ],
  }

  const onClear = () => {
    dispatch({ type: 'adminUserlist/clearAllFilterAndSorter' })
  }

  return (
    <Card
        title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'UserList' })}</b>}
        extra={
            <AnalysisTenantSelect
                showAll={true}
                currentTenant={currentTenant}
                onChange={(e) => {
                    dispatch({ type: 'adminUserlist/changeTenant', payload: { id: e } })
                }}
            />
        }
        bordered={false}
    >
      <Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={8}>
          <Search {...searchProps} />
        </Col>
        <Col span={6}>
          <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
        </Col>
      </Row>
      <MagicTable {...tableProps} />
    </Card>
  )
}

export default connect(({ adminUserlist, loading }) => ({ adminUserlist, loading }))(UserList)
