import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';
import AnalysisChart from './charts/AnalysisChart';

const MentalStatus = ({ hfsReportOverAllFeedback, userInfo, pageNum }) => {
  const intl = useIntl();

  const { mentalFeedbacks = [] } = hfsReportOverAllFeedback || {};

  const { avatarContent } = userInfo || {};

  const showData = mentalFeedbacks.slice(0, 3);

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({
      id: 'TransitionsMental',
    }),
    headerLogo: '',
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.categoryAnalysis}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom tagTitle={intl.formatMessage({ id: 'Mental Statuses' })} />
          <div className={styles.titleDes}>
            {intl.formatMessage(
              { id: 'Mental StatusesDes' },
              { name: avatarContent },
            )}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'six chartsDes' })}
          </div>

          {showData.map((item) => {
            const {
              feedbackProperty = {},
              linearValues = [],
              values = [],
            } = item;
            const { name, id } = feedbackProperty || {};
            const chartProps = {
              analysisValues: values,
              linearValues,
              showKey: name,
              feedbackProperty,
            };
            return (
              <div style={{ marginTop: '10px' }} key={id}>
                <AnalysisChart {...chartProps} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MentalStatus);
