import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import CarsReportTable from 'cognitiveleap-core-us/components/ArchivesV2/UserDataPanel/components/CarsReportTable';
import MFATwoLineChartV2 from 'cognitiveleap-core-us/components/Charts/Archives/MFATwoLineChartV2';

const CarsCharts = ({ analysisCarsData, getCurrentPageNum }) => {
  const intl = useIntl();

  const headerContent = {
    pageNum: getCurrentPageNum(6),
    pageTitle: intl.formatMessage({ id: 'Assessment Statistics' }),
    headerLogo: '',
  };

  const { analysis = [] } = analysisCarsData || {};

  const getMedianValueList = (isMoreAge) => {
    return {
      Q1: isMoreAge ? 2.5 : 3,
      Q2: isMoreAge ? 2 : 2.5,
      Q3: isMoreAge ? 3 : 3,
      Q4: isMoreAge ? 2.5 : 2.5,
      Q5: isMoreAge ? 2 : 2.5,
      Q6: isMoreAge ? 2.5 : 2.5,
      Q7: isMoreAge ? 2 : 2.5,
      Q8: isMoreAge ? 2 : 2.5,
      Q9: isMoreAge ? 2 : 2,
      Q10: isMoreAge ? 2.5 : 2.5,
      Q11: isMoreAge ? 3 : 3,
      Q12: isMoreAge ? 2 : 2.5,
      Q13: isMoreAge ? 2 : 2.5,
      Q14: isMoreAge ? 2.5 : 2.5,
      Q15: isMoreAge ? 3 : 3,
    };
  };

  const reportProps = {
    dataSource: analysis?.slice(0, 3),
    from: 'report',
    getMedianValueList,
  };

  const chartProps = {
    chartData: analysisCarsData,
    from: 'cars',
    showSelectCom: false,
    echartsHeight: '440px',
    getMedianValueList,
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.carsCharts}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom
            tagTitle={intl.formatMessage({ id: 'Childhood Autism Charts' })}
          />

          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'Childhood Autism ChartsDes' })}
          </div>

          <p />

          <CarsReportTable {...reportProps} />
          <p />
          <MFATwoLineChartV2 {...chartProps} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CarsCharts);
