import React from 'react';
import { Space } from 'antd';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import CategoryCharts from './charts/CategoryCharts';

import { useIntl } from 'umi';

import moment from 'moment';

const SkillBasedExerciseTherapy = ({ trainingInfo, getCurrentPageNum }) => {
  const intl = useIntl();

  const {
    trainingCategoryPush = [],
    activityAlreadyFeedback,
    completedSessions,
    trainingPeriod,
    pushedActivity,
  } = trainingInfo || {};

  const { startTime, endTime } = trainingPeriod || {};

  const diffInDays = moment(endTime).diff(moment(startTime), 'days');

  const headerContent = {
    pageNum: getCurrentPageNum(7),
    pageTitle: intl.formatMessage({ id: 'Skill Exercise Therapy' }),
    headerLogo: '',
  };

  const showList = [
    {
      title: intl.formatMessage({ id: 'Training Period' }),
      value:
        moment(startTime).format('MM/DD/YYYY') +
        '-' +
        moment(endTime).format('MM/DD/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'Completed Sessions' }),
      value: completedSessions,
    },
    {
      title: intl.formatMessage({ id: 'Completion Percentage' }),
      value: parseInt((activityAlreadyFeedback / pushedActivity) * 100) + '%',
    },
    {
      title: intl.formatMessage({ id: 'Average Training Frequency' }),
      value: intl.formatMessage(
        { id: 'session_week' },
        {
          num:
            completedSessions > 0
              ? ((completedSessions / diffInDays) * 7).toFixed(1)
              : 0,
        },
      ),
    },
    {
      title: intl.formatMessage({ id: 'Number of Training Activities' }),
      value: activityAlreadyFeedback,
    },
  ];

  return (
    <div id="SkillBasedExercise" className={styles.page_div}>
      <div className={styles.skillBasedExerciseTherapy}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl.formatMessage({ id: 'Skill Exercise Therapy' }).toUpperCase()}
          </div>
          <TagCom tagTitle={intl.formatMessage({ id: 'Overview' })} />

          <div className={styles.showList}>
            {showList.map((item) => {
              const { title, value } = item;
              return (
                <div key={title}>
                  <span className={styles.title}>{title}: </span>
                  <span>{value}</span>
                </div>
              );
            })}
          </div>

          <TagCom
            tagTitle={intl.formatMessage({ id: 'Push and Completion' })}
          />

          <div className={styles.categoryCharts}>
            <CategoryCharts trainingCategoryPush={trainingCategoryPush} />
          </div>

          <table style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th></th>
                {trainingCategoryPush.map((item) => {
                  const { category } = item || {};
                  const { displayName, id } = category || {};
                  return <th key={id}>{displayName}</th>;
                })}
                <th style={{ width: '60px' }}>
                  {intl.formatMessage({ id: 'total' })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{intl.formatMessage({ id: 'Number of Completions' })}</th>
                {trainingCategoryPush.map((item) => {
                  const { numberOfCompletions, category } = item || {};
                  const { id } = category || {};
                  return <td key={id}>{numberOfCompletions}</td>;
                })}
                <td>{activityAlreadyFeedback}</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'Number of Pushes' })}</th>
                {trainingCategoryPush.map((item) => {
                  const { numberOfPushes, category } = item || {};
                  const { id } = category || {};
                  return <td key={id}>{numberOfPushes}</td>;
                })}
                <td>{pushedActivity}</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'Completion Percentage' })}</th>
                {trainingCategoryPush.map((item) => {
                  const { numberOfCompletions, numberOfPushes, category } =
                    item || {};
                  const { id } = category || {};
                  return (
                    <td key={id}>
                      {(numberOfPushes > 0
                        ? parseInt((numberOfCompletions / numberOfPushes) * 100)
                        : 0) + '%'}
                    </td>
                  );
                })}
                <td
                  style={{
                    color: '#2b5b5d',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  {parseInt((activityAlreadyFeedback / pushedActivity) * 100) +
                    '%'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SkillBasedExerciseTherapy);
