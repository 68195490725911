import React from 'react';
import { useIntl, getLocale } from 'umi';
import ReactEcharts from 'echarts-for-react';
import styles from './index.less';

const GraphChart = ({ chartData = {}, showKey }) => {
  const intl = useIntl();

  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const { feedbackProperty, values = [] } = chartData || {};
  const { extraProperties = {} } = feedbackProperty || {};
  const { options = [] } = extraProperties[language] ?? extraProperties;

  const activeOptions = options?.filter(
    (item) => item.value !== 'customizeOthers',
  );

  const xAxisData = [],
    seriesData = [];

  // 两种图表数据处理方式不一样
  const isTransitionalBehavior = showKey === 'transitionalBehavior';

  const { chartTitle, yName, chartHeight, chartWidth, gridOptions } = {
    transitionalBehavior: {
      chartTitle: intl.formatMessage({ id: 'Graph by Transition Behaviors' }),
      yName: 'Transition Behaviors',
      chartHeight: '440px',
      chartWidth: '760px',
      gridOptions: {
        left: 30,
        right: 30,
        bottom: 100,
      },
    },
    behavioralActivity: {
      chartTitle: intl.formatMessage({
        id: 'BehavioralActivity Occurrence Frequency',
      }),
      yName: intl.formatMessage({ id: 'Behavioral Activity' }),
      chartHeight: '300px',
      chartWidth: '700px',
      gridOptions: {
        top: 40,
        left: 30,
        right: 30,
        bottom: 70,
      },
    },
    communicationStyle: {
      chartTitle: intl.formatMessage({
        id: 'Communication Style Occurrence Frequency',
      }),
      yName: intl.formatMessage({ id: 'Communication Style' }),
      chartHeight: '300px',
      chartWidth: '700px',
      gridOptions: {
        top: 40,
        left: 30,
        right: 30,
        bottom: 70,
      },
    },
  }[showKey];

  values.forEach((item) => {
    const { value = [], num } = item || {};

    xAxisData.push(num);
    if (value.length > 0) {
      value.forEach((v) => {
        let _v = parseInt(v);

        if (isTransitionalBehavior) {
          if (_v >= 3) {
            _v = _v - 2;
          } else if (_v === 0) {
            _v = -1;
          } else if (_v === 1) {
            _v = -2;
          } else if (_v === 2) {
            _v = -3;
          }
        } else {
          _v = parseInt(v) + 1;
        }

        // index base 0
        seriesData.push([num - 1, _v]);
      });
    }
  });

  const activeData = seriesData.filter((item) => item);

  const isEmpty = activeData.length === 0;

  const option = {
    color: [
      '#c81d31',
      '#ee822f',
      '#f2ba02',
      '#75bd42',
      '#30c0b4',
      '#249087',
      '#254380',
      '#4d1f70',
    ],
    legend: {
      data: activeOptions.map((item) => item.text),
      bottom: '1%',
      selectedMode: false,
      itemWidth: 12,
    },
    grid: gridOptions,
    title: {
      text: chartTitle,
      left: 'center',
      textStyle: {
        fontSize: 14,
      },
      top: '1%',
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: intl.formatMessage({ id: 'session' }),
      position: 'bottom',
      nameLocation: 'middle',
      nameTextStyle: {
        padding: [12, 0, 0, 0],
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      name: yName,
      nameLocation: 'middle',
      axisLabel: { show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
    },
    series: activeOptions.map((item) => {
      const { text, value } = item || {};
      const _value = parseInt(value);
      return {
        name: text,
        type: 'scatter',
        data: seriesData.filter((item) => {
          const [xValue, bValue] = item || [];
          if (isTransitionalBehavior) {
            if (_value == 0) {
              return bValue == -1;
            } else if (_value == 1) {
              return bValue == -2;
            } else if (_value == 2) {
              return bValue == -3;
            } else {
              return _value - 2 == bValue;
            }
          } else {
            return _value + 1 == bValue;
          }
        }),
      };
    }),
  };

  return (
    <div className={styles.brainLineChart}>
      <ReactEcharts
        option={option}
        notMerge={true}
        opts={{ renderer: 'svg' }}
        style={{ height: chartHeight, width: chartWidth, margin: 'auto' }}
      />
      {isEmpty && (
        <div className={styles.brainLineChartEmpty}>
          {intl.formatMessage({ id: 'emptyData' })}
        </div>
      )}
    </div>
  );
};

export default React.memo(GraphChart);
