import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import SnapReportTable from 'cognitiveleap-core-us/components/ArchivesV2/UserDataPanel/components/SnapReportTable';
import MFATwoLineChartV2 from 'cognitiveleap-core-us/components/Charts/Archives/MFATwoLineChartV2';

const SnapCharts = ({ analysisSnapData, getCurrentPageNum }) => {
  const intl = useIntl();

  const headerContent = {
    pageNum: getCurrentPageNum(6),
    pageTitle: intl.formatMessage({ id: 'Assessment Statistics' }),
    headerLogo: '',
  };

  const reportProps = {
    chartData: analysisSnapData?.slice(0, 3),
    from: 'snap',
  };

  const chartProps = {
    chartData: analysisSnapData?.slice(0, 6),
    from: 'snap',
    showSelectCom: false,
    echartsHeight: '440px',
  };

  return (
    <div className={styles.page_div}>
      <div className={styles.snapCharts}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <TagCom
            tagTitle={intl.formatMessage({ id: 'SNAPIV Attention Charts' })}
          />

          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'SNAPIV Attention ChartsDes' })}
          </div>

          <div style={{ margin: '30px 0' }}>
            <SnapReportTable {...reportProps} />
          </div>
          <MFATwoLineChartV2 {...chartProps} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(SnapCharts);
