import React from 'react';
import styles from './index.less';
import TrainingCalendar from './charts/TrainingCalendar';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

import fullGreen from 'assets/fullGreen.png';
import partialGreen from 'assets/partialGreen.png';

import { useIntl } from 'umi';

import moment from 'moment';

const OverallInformation = ({ userInfo, trainingInfo }) => {
  const intl = useIntl();

  const {
    avatarContent,
    gender,
    birthDay,
    guardians = [],
    coach = [],
  } = userInfo || {};
  const {
    activityAlreadyFeedback,
    completedSessions,
    frequency,
    musicModules = [],
    trainingPeriod,
    trainingCalendars = [],
  } = trainingInfo || {};

  const { startTime, endTime } = trainingPeriod || {};

  const { name: guardianName } = (guardians.length > 0 && guardians[0]) || {};

  const coachNamesList =
    coach && Array.isArray(coach) && coach.length > 0
      ? coach.map((item) => item.name)
      : [];

  const coachNames = coachNamesList.join('; ');

  const headerContent = {
    pageNum: 2,
    pageTitle: intl.formatMessage({ id: 'Overall Information' }),
    headerLogo: '',
  };

  const showList = [
    {
      title: intl.formatMessage({ id: 'name' }),
      value: avatarContent,
    },
    {
      title: intl.formatMessage({ id: 'birthday' }),
      value: moment(birthDay).format('MM/DD/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'gender' }),
      value: gender && intl.formatMessage({ id: gender.toLowerCase() }),
    },
    {
      title: intl.formatMessage({ id: 'guardianName' }),
      value: guardianName,
    },
    {
      title: intl.formatMessage({ id: 'trainer' }),
      value: coachNames,
    },
  ];

  const showList2 = [
    {
      title: intl.formatMessage({ id: 'Training Period' }),
      value:
        moment(startTime).format('MM/DD/YYYY') +
        '-' +
        moment(endTime).format('MM/DD/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'Music Modules' }),
      value: musicModules.map((item) => item.toUpperCase()).join('+'),
    },
    {
      title: intl.formatMessage({ id: 'Completed Sessions' }),
      value: completedSessions,
    },
    {
      title: intl.formatMessage({ id: 'Average Training Frequency' }),
      value: intl.formatMessage(
        { id: 'session_week' },
        {
          num: frequency,
        },
      ),
    },
    {
      title: intl.formatMessage({ id: 'Number of Training Activities' }),
      value: activityAlreadyFeedback,
    },
  ];

  return (
    <div className={styles.page_div}>
      <div className={styles.overallInformation}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div id="PERSONALInformation" className={styles.pageTitle}>
            {intl.formatMessage({ id: 'PERSONAL INFORMATION' })}
          </div>

          <div className={styles.informationList}>
            {showList.map((item) => {
              const { title, value } = item || {};
              return (
                <div className={styles.informationItem} key={title}>
                  <div className={styles.informationItemTitle}>{title}:</div>
                  <div className={styles.informationItemText}>{value}</div>
                </div>
              );
            })}
          </div>

          <div id="TRAININGInformation" className={styles.pageTitle}>
            {intl.formatMessage({ id: 'TRAINING INFORMATION' })}
          </div>

          <div className={styles.informationList}>
            {showList2.map((item) => {
              const { title, value } = item || {};
              return (
                <div className={styles.informationItem} key={title}>
                  <div className={styles.informationItemTitle2}>{title}:</div>
                  <div className={styles.informationItemText}>{value}</div>
                </div>
              );
            })}
          </div>

          <TagCom tagTitle={intl.formatMessage({ id: 'Training Calendar' })} />

          <div className={styles.calendarList}>
            {[...trainingCalendars].reverse().map((item) => {
              const { month } = item || {};
              return <TrainingCalendar key={month} calendarData={item} />;
            })}
          </div>
          <p />
          <div>
            {intl.formatMessage(
              {
                id: 'trainingCalendarTips',
              },
              {
                name: avatarContent,
                redDot: (
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#ff80c0',
                      height: '10px',
                      width: '10px',
                      borderRadius: '50%',
                      margin: '0 5px',
                    }}
                  ></span>
                ),
                greenDot: (
                  <img
                    style={{ height: '16px', width: '16px', margin: '0 5px' }}
                    src={fullGreen}
                    alt=""
                  />
                ),
                partialGreenDot: (
                  <img
                    style={{ height: '16px', width: '16px', margin: '0 5px' }}
                    src={partialGreen}
                    alt=""
                  />
                ),
              },
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OverallInformation);
