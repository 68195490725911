import { useIntl, getLocale } from 'umi';

import styles from './index.less';

const MainComplaint = ({ mainComplaintValue, trainingTargetValue }) => {
  const intl = useIntl();

  const isZh = getLocale() === 'zh-CN';

  const leftTitleStyle = isZh ? styles.leftTitleZh : styles.leftTitleEn;

  return (
    <>
      {mainComplaintValue.map((item, index) => {
        const { text, brainNetwork, brainNetworkHelps } = item || {};
        return (
          <div className={styles.classPlanContent} key={text}>
            <div className={styles.descItem}>
              <span className={leftTitleStyle}>
                {intl.formatMessage({ id: 'Main Complaint' })} {index + 1}:{' '}
              </span>
              <span className={styles.rightLightDes}>{text}</span>
            </div>
            <div className={styles.descItem}>
              <span className={leftTitleStyle}>
                {intl.formatMessage({ id: 'Brain Network Regions' })}:{' '}
              </span>
              <span className={styles.rightDes}>{brainNetwork}</span>
            </div>
            <div className={styles.descItem}>
              <span className={leftTitleStyle}>
                {intl.formatMessage({ id: 'Intervention Plan' })}:{' '}
              </span>
              <span className={styles.rightDes}>{brainNetworkHelps}</span>
            </div>
          </div>
        );
      })}
      <div className={styles.classPlanContent}>
        <div className={styles.descItem}>
          <span className={leftTitleStyle}>
            {intl.formatMessage({ id: 'Desired Outcomes' })}:{' '}
          </span>
          <span className={styles.rightDes}>{trainingTargetValue}</span>
        </div>
      </div>
    </>
  );
};

export default MainComplaint;
