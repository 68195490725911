import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect, useIntl, getLocale } from 'umi';
import { Layout, Spin, BackTop, message } from 'antd';
import LocaleSelection from 'cognitiveleap-core-us/components/LocaleSelection';
import { resolveSurveyDataByPage } from 'cognitiveleap-core-us/utils/utils';
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';
import { useReactToPrint } from 'react-to-print';
import DocumentTitle from 'react-document-title';

import { MyContext } from './context';

import CoverPage from './CoverPage';
import Contents from './Contents';
import WelcomeLetters from './WelcomeLetters';
import OverallInformation from './OverallInformation';
import ChildDevelopmentHistory from './ChildDevelopmentHistory';
import StrengthFirst from './StrengthFirst';
import StrengthTwo from './StrengthTwo';
import StrengthThree from './StrengthThree';
import StrengthFollowUp from './StrengthFollowUp';
import VcatStatisticsCharts from './VcatStatisticsCharts';
import FundamentalTables from './FundamentalTables';
import FundamentalCharts from './FundamentalCharts';
import CarsCharts from './CarsCharts';
import SnapCharts from './SnapCharts';
import MusicListeningTherapy from './MusicListeningTherapy';
import SkillBasedExerciseTherapy from './SkillBasedExerciseTherapy';
import CategoryAnalysis from './CategoryAnalysis';
import CategoryAnalysisFirst from './CategoryAnalysisFirst';
import CategoryAnalysisTwo from './CategoryAnalysisTwo';
import TransitionBehaviors from './TransitionBehaviors';
import MentalStatus from './MentalStatus';
import MentalStatusNext from './MentalStatusNext';
import FollowingBehaviors from './FollowingBehaviors';

import {
  PostUserInfo,
  GetTrainingInfo,
  GetHfsReportChildDevelopment,
  GetHfsReportStrengthBasedInterview,
  GetHfsReportStrengthBasedInterviewFollowUp,
  GetHfsVCatStatistical,
  GetHfsReportAnalysis,
  GetHfsReportCars2,
  GetHfsReportSnapIv,
  GetHfsReportCategoryAnalysis,
  GetHfsReportOverAllFeedback,
} from 'services/InterventionReport';
import { GetBrainRecord } from 'services/rocketService/UserOfflinePlan';

import styles from './index.less';

import moment from 'moment';

const { Content } = Layout;

const momentLangList = {
  'zh-CN': 'zh-cn',
  'en-US': 'en-us',
  'es-ES': 'es-es',
};

// 获取实际页面
const getCurrentPageNum = (num, hiddenPage = []) => {
  const [
    isShowChildHistoryPage,
    isShowStrengthPage,
    isShowStrengthFollowUpPage,
    isShowCarsPage,
    isShowSnapPage,
  ] = hiddenPage || [];

  let _num = num;

  if (isShowChildHistoryPage) {
    _num += 1;
  }
  if (isShowStrengthPage) {
    _num += 3;
  }
  if (isShowStrengthFollowUpPage) {
    _num += 1;
  }
  if (isShowCarsPage) {
    _num += 1;
  }
  if (isShowSnapPage) {
    _num += 1;
  }
  return _num;
};

const InterventionReport = ({ dispatch, location }) => {
  const intl = useIntl();

  // type=download 隐藏打印按钮
  const { suspension, id, userId, currentTenantId } = location.query || {};

  moment.locale(momentLangList[getLocale()]); // 默认的语言环境为英语

  const componentRef = useRef();

  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [trainingInfo, setTrainingInfo] = useState({});
  const [childDevelopmentHistoryData, setChildDevelopmentHistory] = useState(
    {},
  );
  const [strengthBasedInterviewData, setStrengthBasedInterview] = useState({});
  const [
    strengthBasedInterviewFollowUpData,
    setStrengthBasedInterviewFollowUp,
  ] = useState({});
  const [vcatStatistical, setVcatStatistical] = useState({});
  const [fasReportData, setFasReportData] = useState({});
  const [analysisCarsData, setAnalysisCarsData] = useState({});
  const [analysisSnapData, setAnalysisSnapData] = useState([]);
  const [musicProgressData, setMusicProgressData] = useState({});
  const [hfsReportCategoryAnalysis, setHfsReportCategoryAnalysis] = useState(
    [],
  );
  const [hfsReportOverAllFeedback, setHfsReportOverAllFeedback] = useState({});

  const { avatarContent } = userInfo || {};

  const { answer: strengthAnswer, questionnaire: strengthQuestionnaire } =
    strengthBasedInterviewData || {};
  const {
    answers: strengthFollowUpAnswers = [],
    questionnaire: strengthFollowUpQuestionnaire,
  } = strengthBasedInterviewFollowUpData || {};

  let { jsonString: strengthJsonString } = strengthQuestionnaire || {};
  let { answerContent: strengthAnswerContent } = strengthAnswer || {};

  let { jsonString: strengthFollowUpJsonString } =
    strengthFollowUpQuestionnaire || {};

  strengthJsonString = strengthJsonString && JSON.parse(strengthJsonString);
  strengthAnswerContent =
    strengthAnswerContent && JSON.parse(strengthAnswerContent);

  strengthFollowUpJsonString =
    strengthFollowUpJsonString && JSON.parse(strengthFollowUpJsonString);

  const strengthSurveyData = resolveSurveyDataByPage(
    strengthAnswerContent,
    strengthJsonString,
  );

  const strengthFollowUpAnswerList = (strengthFollowUpAnswers || []).map(
    (item) => {
      let { answerContent } = item;
      answerContent = answerContent && JSON.parse(answerContent);

      return {
        ...item,
        parseAnswer: resolveSurveyDataByPage(
          answerContent,
          strengthFollowUpJsonString,
        ),
      };
    },
  );

  const onClickDownLoad = useReactToPrint({
    content: () => componentRef.current,
  });

  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'downLoadReport' }),
          onClick: () => onClickDownLoad(),
        },
        {
          name: intl.formatMessage({ id: 'selectLanguage' }),
          children: [
            {
              name: '简体中文',
              active: getLocale() === 'zh-CN',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'zh-CN' });
              },
              className: getLocale() === 'zh-CN' ? 'chineseStyle' : '_',
            },
            {
              name: 'English',
              active: getLocale() === 'en-US',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'en-US' });
              },
              className: getLocale() === 'en-US' ? 'englishStyle' : '_',
            },
            {
              name: 'Español',
              active: getLocale() === 'es-ES',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'es-ES' });
              },
              className: getLocale() === 'es-ES' ? 'englishStyle' : '_',
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ].filter((item) => item),
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  const initData = async () => {
    setLoading(true);

    const params = {
      userOfflinePlanId: id,
    };

    const resList = await Promise.all([
      PostUserInfo({ subjectId: userId, currentTenantId }),
      GetTrainingInfo(params),
      GetHfsReportChildDevelopment(params),
      GetHfsReportStrengthBasedInterview(params),
      GetHfsReportStrengthBasedInterviewFollowUp(params),
      GetHfsReportAnalysis(params),
      GetHfsReportCars2(params),
      GetHfsReportSnapIv(params),
      GetBrainRecord({ UserOfflineTrainingPlanId: id }),
      GetHfsReportCategoryAnalysis(params),
      GetHfsReportOverAllFeedback(params),
    ]);

    if (resList.every((res) => res.response.ok)) {
      const [
        userInfoData,
        trainingInfoData,
        childDevelopmentData,
        strengthBasedInterviewData,
        strengthBasedInterviewFollowUpData,
        fasAnalysisData,
        carsData,
        snapData,
        brainRecordData,
        categoryAnalysisData,
        overallFeedbackData,
      ] = resList.map((res) => res.data || {});

      const { trainingPeriod } = trainingInfoData;
      const { startTime } = trainingPeriod || {};

      const resList2 = await Promise.all([
        GetHfsVCatStatistical({
          userId,
          startTime: moment(startTime).format(),
        }),
      ]);

      if (resList2.every((res) => res.response.ok)) {
        const [vcatStatistical] = resList2.map((res) => res.data || {});
        setVcatStatistical(vcatStatistical);
      }

      const analysis = Object.values(carsData)
        .filter((item) => item.length > 0)
        .flat()
        .sort((a, b) =>
          moment(a.finishTime).isBefore(moment(b.finishTime)) ? 1 : -1,
        );
      const { items: snapItem = [] } = snapData;

      setUserInfo(userInfoData);
      setTrainingInfo(trainingInfoData);
      setChildDevelopmentHistory(childDevelopmentData);
      setStrengthBasedInterview(strengthBasedInterviewData);
      setStrengthBasedInterviewFollowUp(strengthBasedInterviewFollowUpData);
      setFasReportData(fasAnalysisData);
      setAnalysisCarsData({
        analysis,
        ...carsData,
      });
      setAnalysisSnapData(snapItem);
      setMusicProgressData(brainRecordData);
      setHfsReportCategoryAnalysis(
        Object.keys(categoryAnalysisData).length > 0
          ? categoryAnalysisData
          : [],
      );
      setHfsReportOverAllFeedback(overallFeedbackData);
    } else {
      message.error('Api failed!');
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
    // 初始先禁用右键菜单功能，等页面加载完毕会重写右键菜单功能
    if (suspension) {
      initCustomMenu();

      document.addEventListener('contextmenu', (event) =>
        event.preventDefault(),
      );
      // 禁用浏览器快捷键打印功能
      window.addEventListener('keydown', function (e) {
        if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
          e.preventDefault();
          onClickDownLoad();
        }
      });
    }
  }, []);

  // 页面是否显示处理
  const { answer } = childDevelopmentHistoryData || {};
  const { lastModificationTime, creationTime } = answer || {};
  const finishTime = lastModificationTime || creationTime;

  const { analysis = [] } = analysisCarsData || {};

  const isShowChildHistoryPage = finishTime != null || finishTime != undefined; // 1页
  const isShowStrengthPage =
    strengthSurveyData && strengthSurveyData.length > 0; // 3页
  const isShowStrengthFollowUpPage =
    strengthFollowUpAnswerList && strengthFollowUpAnswerList.length > 0; // 1页

  const isShowCarsPage = analysis && analysis.length > 0; // 1页
  const isShowSnapPage = analysisSnapData && analysisSnapData.length > 0; // 1页

  const hiddenPageData = [
    isShowChildHistoryPage,
    isShowStrengthPage,
    isShowStrengthFollowUpPage,
    isShowCarsPage,
    isShowSnapPage,
  ];

  // 分类循环起始页 结束页面
  const startPage = getCurrentPageNum(8, hiddenPageData);
  const endPage = startPage + hfsReportCategoryAnalysis.length * 2;

  const contentsProps = {
    hiddenPageData,
    hfsReportCategoryAnalysis,
    startPage,
    endPage,
    getCurrentPageNum,
  };

  const userInfoProps = {
    userInfo,
  };

  const overallProps = {
    userInfo,
    trainingInfo,
  };

  const childHistoryProps = {
    childDevelopmentHistoryData,
  };

  const strengthProps = {
    userInfo,
    strengthBasedInterviewData,
    strengthSurveyData,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 1)),
  };

  const followUpProps = {
    userInfo,
    strengthFollowUpAnswerList,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 2)),
  };

  const vcatChartProps = {
    userInfo,
    vcatStatistical,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 3)),
  };

  const fasProps = {
    fasReportData,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 3)),
  };

  const carsProps = {
    analysisCarsData,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 3)),
  };

  const snapProps = {
    analysisSnapData,
    getCurrentPageNum: (num) =>
      getCurrentPageNum(num, hiddenPageData.slice(0, 4)),
  };

  const progressProps = {
    trainingInfo,
    musicProgressData,
    getCurrentPageNum: (num) => getCurrentPageNum(num, hiddenPageData),
  };

  const skillProps = {
    trainingInfo,
    getCurrentPageNum: (num) => getCurrentPageNum(num, hiddenPageData),
  };

  const categoryProps = {
    hfsReportCategoryAnalysis,
    getCurrentPageNum: (num) => getCurrentPageNum(num, hiddenPageData),
  };

  const transitionBehaviorsProps = {
    userInfo,
    hfsReportOverAllFeedback,
  };

  return (
    <DocumentTitle
      title={`${intl.formatMessage({
        id: 'HFS Intervention Report',
      })}-${avatarContent}-${moment().format('MM/DD/YYYY')}`}
    >
      <>
        {suspension && !loading && (
          <>
            <div className={styles.suspension}>
              <ul>
                <li
                  className={styles.downLoadStyle}
                  onClick={onClickDownLoad}
                ></li>
                <li className={styles.selectStyle} id="select">
                  <LocaleSelection
                    changeLanguage={(key) =>
                      dispatch({ type: 'global/changeLang', payload: key })
                    }
                    dropDownProps={{
                      getPopupContainer: () => {
                        return document.getElementById('select');
                      },
                      overlayStyle: { width: 80 },
                    }}
                    styles={{ width: '64px', height: '64px' }}
                  />
                </li>
              </ul>
            </div>
            <BackTop visibilityHeight={900}>
              <div className={styles.backUpStyle}></div>
            </BackTop>
          </>
        )}
        <MyContext.Provider value={{}}>
          <Spin spinning={loading}>
            <Layout ref={componentRef} className={styles.reportwrapper}>
              <Content className={styles.content}>
                <div className={styles.pageWrapper}>
                  <CoverPage {...userInfoProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <Contents {...contentsProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <WelcomeLetters {...userInfoProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <OverallInformation {...overallProps} />
                </div>

                {isShowChildHistoryPage && (
                  <div className={styles.pageWrapper}>
                    <ChildDevelopmentHistory {...childHistoryProps} />
                  </div>
                )}

                {isShowStrengthPage && (
                  <>
                    <div className={styles.pageWrapper}>
                      <StrengthFirst {...strengthProps} />
                    </div>

                    <div className={styles.pageWrapper}>
                      <StrengthTwo {...strengthProps} />
                    </div>

                    <div className={styles.pageWrapper}>
                      <StrengthThree {...strengthProps} />
                    </div>
                  </>
                )}

                {isShowStrengthFollowUpPage && (
                  <div className={styles.pageWrapper}>
                    <StrengthFollowUp {...followUpProps} />
                  </div>
                )}

                <div className={styles.pageWrapper}>
                  <VcatStatisticsCharts {...vcatChartProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <FundamentalTables {...fasProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <FundamentalCharts {...fasProps} />
                </div>

                {isShowCarsPage && (
                  <div className={styles.pageWrapper}>
                    <CarsCharts {...carsProps} />
                  </div>
                )}

                {isShowSnapPage && (
                  <div className={styles.pageWrapper}>
                    <SnapCharts {...snapProps} />
                  </div>
                )}

                <div className={styles.pageWrapper}>
                  <MusicListeningTherapy {...progressProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <SkillBasedExerciseTherapy {...skillProps} />
                </div>

                <div className={styles.pageWrapper}>
                  <CategoryAnalysis {...categoryProps} />
                </div>

                {hfsReportCategoryAnalysis.map((item, index) => {
                  const { trainingCategory } = item || {};
                  const { id } = trainingCategory || {};
                  const _startPage = startPage + index * 2;

                  return (
                    <Fragment key={id}>
                      <div className={styles.pageWrapper}>
                        <CategoryAnalysisFirst
                          categoryItem={item}
                          pageNum={_startPage + 1}
                        />
                      </div>
                      <div className={styles.pageWrapper}>
                        <CategoryAnalysisTwo
                          categoryItem={item}
                          pageNum={_startPage + 2}
                        />
                      </div>
                    </Fragment>
                  );
                })}

                <div className={styles.pageWrapper}>
                  <TransitionBehaviors
                    {...transitionBehaviorsProps}
                    pageNum={endPage + 1}
                  />
                </div>

                <div className={styles.pageWrapper}>
                  <MentalStatus
                    {...transitionBehaviorsProps}
                    pageNum={endPage + 2}
                  />
                </div>

                <div className={styles.pageWrapper}>
                  <MentalStatusNext
                    {...transitionBehaviorsProps}
                    pageNum={endPage + 3}
                  />
                </div>

                <div className={styles.pageWrapper}>
                  <FollowingBehaviors
                    {...transitionBehaviorsProps}
                    pageNum={endPage + 4}
                  />
                </div>

                {/* 后端下载匹配字段 */}
                {false ? (
                  <div id={'report ready'} key={'report ready'} />
                ) : null}
              </Content>
            </Layout>
          </Spin>
        </MyContext.Provider>
      </>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  InterventionReport,
);
