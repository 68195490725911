import React from 'react';
import styles from '../index.less';
const TagCom = ({ tagTitle, tagStyle }) => {
  return (
    <div className={styles.tagContent} style={tagStyle}>
      <div className={styles.tags}></div>
      <div className={styles.tagTitle}>{tagTitle}</div>
    </div>
  );
};

export default React.memo(TagCom);
