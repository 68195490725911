import React from 'react';
import { useIntl } from 'umi';
import { Image } from 'antd';
import styles from './index.less';
import HeaderContent from './components/HeaderContent';
import TagCom from './components/TagCom';

const CategoryAnalysisFirst = ({ categoryItem, pageNum }) => {
  const intl = useIntl();

  const { trainingCategory } = categoryItem || {};
  const {
    displayName,
    name,
    brainImages = [],
    deficits = [],
    works = [],
  } = trainingCategory || {};
  const { url } = brainImages.length > 0 ? brainImages[0] : {};

  const headerContent = {
    pageNum,
    pageTitle: intl.formatMessage({ id: 'SkillDisplayName' }, { displayName }),
    headerLogo: '',
  };

  const getSplitType = (content) => {
    if (content) {
      if (content.includes('：')) {
        return '：';
      }
      if (content.includes(':')) {
        return ':';
      }
    }

    return ':';
  };

  return (
    <div
      id={'SkillBasedExerciseCategoryAnalysis' + name}
      className={styles.page_div}
    >
      <div className={styles.categoryAnalysis}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl
              .formatMessage({ id: 'SkillDisplayName' }, { displayName })
              .toUpperCase()}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage(
              {
                id: 'CategoryTips',
              },
              { Category: displayName },
            )}
          </div>
          <TagCom
            tagTitle={intl.formatMessage(
              { id: 'Category Instruction DisplayName' },
              { displayName },
            )}
          />

          <div className={styles.descItem}>
            <div className={styles.itemLeft}>
              <div className={styles.itemContent}>
                <div className={styles.itemContentTitle}>
                  {intl.formatMessage({ id: 'Mechanism of Action' })}
                </div>
                {works?.map((work) => {
                  const { content } = work || {};
                  const [title, des] = content?.split(getSplitType(content));

                  return (
                    <div className={styles.itemContentDetail} key={title}>
                      <span className={styles.itemDetailTitle}>{title}: </span>
                      <span>{des}</span>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className={styles.itemContent}>
                <div className={styles.itemContentTitle}>
                  {intl.formatMessage({
                    id: 'Challenges Areas for Improvement',
                  })}
                </div>
                {deficits?.map((deficit) => {
                  const { content, example } = deficit || {};
                  const [title, des] = content?.split(getSplitType(content));
                  return (
                    <div className={styles.itemContentDetail} key={title}>
                      <span className={styles.itemDetailTitle}>{title}: </span>
                      <span>{des}</span>
                      <div className={styles.everydayLives}>
                        <div className={styles.everydayLivesTitle}>
                          {intl.formatMessage({
                            id: 'Examples of daily life',
                          })}
                          :{' '}
                        </div>
                        <div>{example}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.itemRight}>
              {url && <Image width={240} src={url} preview={false} alt="" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryAnalysisFirst);
