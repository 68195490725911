import React from 'react';
import { useIntl } from 'umi';
import { resolveSurveyData } from 'cognitiveleap-core-us/utils/utils';

import TagCom from './components/TagCom';
import HeaderContent from './components/HeaderContent';

import moment from 'moment';

import styles from './index.less';

const ChildDevelopmentHistory = ({ childDevelopmentHistoryData }) => {
  const intl = useIntl();

  const { answer, questionnaire } = childDevelopmentHistoryData || {};

  const { lastModificationTime, creationTime } = answer || {};

  const finishTime = lastModificationTime || creationTime;

  const { answerContent } = answer || {};
  const { jsonString } = questionnaire || {};

  const feedbackData = resolveSurveyData(
    JSON.parse(answerContent || '{}'),
    JSON.parse(jsonString || '{}'),
    'allData',
  );

  const dateList = [
    'yearOfDiagnoseASD',
    'yearOfDiagnoseADHD',
    'yearOfDiagnoseLD',
    'yearOfDiagnoseID',
    'yearOfDiagnoseSLD',
    'yearOfDiagnoseSPD',
    'yearOfDiagnoseDCD',
    'yearOfDiagnoseODD',
    'yearOfDiagnoseCD',
    'yearOfDiagnoseSCD',
  ];

  const showInfoList = [
    {
      title: 'Parent Guardian Concerns',
      leftContent: ['mainComplaint', 'firstNotice'],
      rightContent: ['changesOrStressors', 'trainingTarget'],
    },
    {
      title: 'Child Communication Traits and Habits',
      leftContent: [
        'understandingDifficulty',
        'expressNeed',
        'nonverbalCommunication',
        'respondToInstruction',
        'connectionWay',
      ],
      rightContent: [
        'physicalActivity',
        'hobby',
        'anxietySituation',
        'otherInfo',
      ],
    },
    {
      title: 'Diagnosis Information',
      leftContent: ['developmentDisorder', 'prescriptionMedication'],
      rightContent: ['specialEducationSevice', 'intervention', 'allergy'],
    },
  ];

  const showKeyData = (key) => {
    let _title, _value;
    const findData = feedbackData.find((item) => item.name === key) || {};

    if (Object.keys(findData || {}).length > 0) {
      const { title, value } = findData;

      _title = title;

      if (Object.prototype.toString.call(value) === '[object Array]') {
        if (key === 'developmentDisorder') {
          _value = value
            .map((item) => {
              const { value: itemValue, text, isOther } = item || {};

              if (isOther) {
                return `${text} - ${itemValue}`;
              } else {
                const _findData =
                  feedbackData.find(
                    (item) => item.name === dateList[itemValue - 1],
                  ) || {};

                if (Object.keys(_findData || {}).length > 0) {
                  const { value: _value } = _findData;
                  return _value ? text + ' - ' + _value : text;
                } else {
                  return text;
                }
              }
            })
            .join('\n');
        } else {
          _value = value
            .map((item) => {
              const { value: itemValue, text, isOther } = item || {};
              if (isOther) {
                return `${text} - ${itemValue}`;
              }
              return text;
            })
            .join(';\n');
        }
      } else {
        _value = value || '';
      }
    }

    return {
      _title,
      _value,
    };
  };

  const headerContent = {
    pageNum: 3,
    pageTitle: intl.formatMessage({ id: 'Child Development History' }),
    headerLogo: '',
  };

  return (
    <div id="ChildDevelopmentHistory" className={styles.page_div}>
      <div className={styles.childDevelopmentHistory}>
        <HeaderContent {...headerContent} />
        <div className={styles.centerContent}>
          <div className={styles.pageTitle}>
            {intl
              .formatMessage({ id: 'Child Development History' })
              .toUpperCase()}
          </div>

          <div className={styles.titleDes}>
            This section contains child initial's Developmental Information,
            including the Parent / Guardian's main concerns, past diaognsis
            information (if any), and other information related to communication
            and behaviors.
          </div>

          {showInfoList.map((item, index) => {
            const { title, leftContent = [], rightContent = [] } = item || {};

            return (
              <div key={index} className={styles.dataInfoContent}>
                <div className={styles.infoTitleContent}>
                  <TagCom tagTitle={title} />
                </div>
                <div className={styles.infoContent}>
                  <div className={styles.infoContentLeft}>
                    {leftContent.map((item) => {
                      const { _title, _value } = showKeyData(item);
                      return (
                        <div key={item} className={styles.infoItem}>
                          <div className={styles.infoItemTitle}>{_title}</div>
                          <div className={styles.infoItemValue}>{_value}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.infoContentRight}>
                    {rightContent.map((item) => {
                      const { _title, _value } = showKeyData(item);

                      return (
                        <div key={item} className={styles.infoItem}>
                          <div className={styles.infoItemTitle}>{_title}</div>
                          <div className={styles.infoItemValue}>{_value}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}

          <div className={styles.submissionTime}>
            {intl.formatMessage({ id: 'Test Time' })}:{' '}
            {moment(finishTime).format('MM/DD/YYYY HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChildDevelopmentHistory);
